import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-black text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <Link to="/" className="text-2xl font-bold">
              Robavo Consulting
            </Link>
            <p className="mt-4 text-gray-400">
              Your Trusted Partner in IT Solutions for Governments and Businesses
            </p>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Services</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/services/cloud" className="text-gray-400 hover:text-white transition-colors">
                  Cloud Services
                </Link>
              </li>
              <li>
                <Link to="/services/security" className="text-gray-400 hover:text-white transition-colors">
                  Security Services
                </Link>
              </li>
              <li>
                <Link to="/services/digital-workplace" className="text-gray-400 hover:text-white transition-colors">
                  Digital Workplace
                </Link>
              </li>
              <li>
                <Link to="/services/ai-automation" className="text-gray-400 hover:text-white transition-colors">
                  AI & Automation
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Company</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-gray-400 hover:text-white transition-colors">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/founders-letter" className="text-gray-400 hover:text-white transition-colors">
                  Founder's Letter
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Contact Us</h3>
            <ul className="space-y-3">
              <li className="text-gray-400">
                100 West Road, Suite 300
                <br />
                Towson, MD 21204
              </li>
              <li>
                <a href="mailto:consult@robavo.xyz" className="text-gray-400 hover:text-white transition-colors">
                  consult@robavo.xyz
                </a>
              </li>
              <li>
                <a href="tel:+15551234567" className="text-gray-400 hover:text-white transition-colors">
                  +1 (415) 535-1941
                </a>
              </li>
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-white transition-colors">
                  Book Your Free Consultation
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-16 pt-8 border-t border-gray-800">
          <p className="text-center text-gray-400">
            © {new Date().getFullYear()} Robavo Expeditions LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}