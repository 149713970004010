import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Shield, Lock, FileCheck, Bell } from 'lucide-react';
import { CaseStudy } from '../CaseStudy';

export function SecurityServices() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <section className="pt-32 pb-16 sm:pt-40 sm:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="mb-8 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <Link to="/services" className="inline-flex items-center text-purple-500 hover:text-purple-400 transition-colors group">
              <ArrowLeft className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:-translate-x-1" />
              Back to Services
            </Link>
          </div>

          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">Security Services</h1>
            <p className="text-xl text-gray-400 mb-12 max-w-3xl">
              Protect your organization with enterprise-grade security solutions designed for modern threats and compliance requirements.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Cybersecurity Solutions</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Comprehensive protection against modern cyber threats:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Advanced threat detection and response",
                      "Zero-trust security architecture",
                      "Network security monitoring",
                      "Endpoint protection and EDR",
                      "Security awareness training",
                      "Incident response planning",
                      "Vulnerability assessment",
                      "Penetration testing"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Shield className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Access Management</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Secure identity and access management solutions:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Multi-factor authentication (MFA)",
                      "Single sign-on (SSO)",
                      "Privileged access management",
                      "Identity governance",
                      "Access control policies",
                      "User activity monitoring",
                      "Password management"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Lock className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Compliance & Governance</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Meet industry standards and regulatory requirements:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "HIPAA compliance",
                      "GDPR requirements",
                      "SOC 2 certification",
                      "NIST framework alignment",
                      "Security policy development",
                      "Compliance monitoring",
                      "Regular security audits",
                      "Risk assessment"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <FileCheck className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <div className="bg-purple-600/10 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20">
                  <h2 className="text-2xl font-bold mb-4">Ready to Secure Your Organization?</h2>
                  <p className="text-gray-400 mb-6">
                    Book a free consultation with our security experts and discover how we can help protect your business from modern threats.
                  </p>
                  <Link to="/contact" className="btn-primary group">
                    Book Your Free Consultation
                    <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Case Studies Section */}
          <div className="mt-24">
            <h2 className="text-2xl font-bold mb-12">Client Success Stories</h2>
            <div className="grid lg:grid-cols-2 gap-8">
              <CaseStudy
                industry="Financial Services"
                challenge="A financial institution faced increasing cyber threats and needed to strengthen their security posture while meeting strict regulatory requirements."
                solution="Implemented a comprehensive security solution including zero-trust architecture, advanced threat detection, and automated incident response."
                results={[
                  "Reduced security incidents by 85%",
                  "Achieved SOC 2 Type II compliance",
                  "Improved threat detection time by 90%",
                  "Enhanced regulatory compliance reporting"
                ]}
                technologies={[
                  "Crowdstrike", "Splunk", "Okta",
                  "Azure Sentinel", "HashiCorp Vault"
                ]}
                testimonial={{
                  quote: "The security implementation has given us peace of mind and helped us maintain our reputation for data protection.",
                  author: "Lisa Thompson",
                  title: "CISO"
                }}
              />

              <CaseStudy
                industry="E-commerce"
                challenge="A rapidly growing e-commerce platform needed to protect customer data and ensure PCI DSS compliance while maintaining high performance."
                solution="Deployed a multi-layered security architecture with WAF, DDoS protection, and real-time monitoring, integrated with their existing infrastructure."
                results={[
                  "Achieved PCI DSS compliance",
                  "Zero data breaches since implementation",
                  "Reduced false positives by 75%",
                  "Maintained 99.99% uptime"
                ]}
                technologies={[
                  "CloudFlare", "AWS WAF", "Prisma Cloud",
                  "AWS Shield", "AWS GuardDuty"
                ]}
                testimonial={{
                  quote: "Our customers' data security is our top priority, and Robavo helped us achieve the highest standards of protection.",
                  author: "David Martinez",
                  title: "Head of Security"
                }}
              />
            </div>
            
            <div className="mt-12 text-center">
              <Link to="/case-studies" className="btn-secondary group">
                View All Case Studies
                <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}