import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader2, Save, ArrowLeft } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { blogPostSchema, type BlogPostFormData } from '../../lib/validation';

export function BlogPostForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm<BlogPostFormData>({
    resolver: zodResolver(blogPostSchema)
  });

  useEffect(() => {
    fetchCategories();
    if (id) {
      fetchPost(id);
    }
  }, [id]);

  async function fetchCategories() {
    try {
      const { data, error } = await supabase
        .from('blog_categories')
        .select('*')
        .order('name');

      if (error) throw error;
      setCategories(data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  async function fetchPost(id: string) {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select(`
          *,
          categories:blog_post_categories(
            category:blog_categories(*)
          )
        `)
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        reset({
          title: data.title,
          slug: data.slug,
          content: data.content,
          excerpt: data.excerpt || '',
          featured_image: data.featured_image || '',
          meta_title: data.meta_title || '',
          meta_description: data.meta_description || '',
          published: data.published,
          categories: data.categories.map(c => c.category.id)
        });
      }
    } catch (error) {
      console.error('Error fetching post:', error);
      navigate('/admin/blog');
    }
  }

  const onSubmit = async (data: BlogPostFormData) => {
    setLoading(true);
    try {
      if (id) {
        // Update existing post
        const { error } = await supabase
          .from('blog_posts')
          .update({
            title: data.title,
            slug: data.slug,
            content: data.content,
            excerpt: data.excerpt,
            featured_image: data.featured_image,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            published: data.published,
            published_at: data.published ? new Date().toISOString() : null,
            updated_at: new Date().toISOString()
          })
          .eq('id', id);

        if (error) throw error;

        // Update categories
        await supabase
          .from('blog_post_categories')
          .delete()
          .eq('post_id', id);

        if (data.categories.length > 0) {
          const { error: categoriesError } = await supabase
            .from('blog_post_categories')
            .insert(
              data.categories.map(categoryId => ({
                post_id: id,
                category_id: categoryId
              }))
            );

          if (categoriesError) throw categoriesError;
        }
      } else {
        // Create new post
        const { data: newPost, error } = await supabase
          .from('blog_posts')
          .insert({
            title: data.title,
            slug: data.slug,
            content: data.content,
            excerpt: data.excerpt,
            featured_image: data.featured_image,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            published: data.published,
            published_at: data.published ? new Date().toISOString() : null,
            author_id: (await supabase.auth.getUser()).data.user?.id
          })
          .select()
          .single();

        if (error) throw error;

        if (data.categories.length > 0) {
          const { error: categoriesError } = await supabase
            .from('blog_post_categories')
            .insert(
              data.categories.map(categoryId => ({
                post_id: newPost.id,
                category_id: categoryId
              }))
            );

          if (categoriesError) throw categoriesError;
        }
      }

      navigate('/admin/blog');
    } catch (error) {
      console.error('Error saving post:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <button
          onClick={() => navigate('/admin/blog')}
          className="flex items-center text-purple-500 hover:text-purple-400 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Posts
        </button>

        <h1 className="text-3xl font-bold mb-8">
          {id ? 'Edit Post' : 'New Post'}
        </h1>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Title
            </label>
            <input
              type="text"
              {...register('title')}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-500">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Slug
            </label>
            <input
              type="text"
              {...register('slug')}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.slug && (
              <p className="mt-1 text-sm text-red-500">{errors.slug.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Content
            </label>
            <textarea
              {...register('content')}
              rows={20}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.content && (
              <p className="mt-1 text-sm text-red-500">{errors.content.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Excerpt
            </label>
            <textarea
              {...register('excerpt')}
              rows={3}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.excerpt && (
              <p className="mt-1 text-sm text-red-500">{errors.excerpt.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Featured Image URL
            </label>
            <input
              type="text"
              {...register('featured_image')}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.featured_image && (
              <p className="mt-1 text-sm text-red-500">{errors.featured_image.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Categories
            </label>
            <select
              multiple
              {...register('categories')}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            >
              {categories.map((category: any) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            {errors.categories && (
              <p className="mt-1 text-sm text-red-500">{errors.categories.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Meta Title
            </label>
            <input
              type="text"
              {...register('meta_title')}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.meta_title && (
              <p className="mt-1 text-sm text-red-500">{errors.meta_title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Meta Description
            </label>
            <textarea
              {...register('meta_description')}
              rows={2}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg px-4 py-2 text-white"
            />
            {errors.meta_description && (
              <p className="mt-1 text-sm text-red-500">{errors.meta_description.message}</p>
            )}
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              {...register('published')}
              className="h-4 w-4 rounded border-gray-700 text-purple-600 focus:ring-purple-500 bg-gray-900"
            />
            <label className="ml-2 text-sm text-gray-400">
              Publish post
            </label>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="btn-primary"
            >
              {loading ? (
                <>
                  <Loader2 className="animate-spin h-5 w-5 mr-2" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-5 w-5 mr-2" />
                  Save Post
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}