import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Cloud, Shield, Monitor, Brain, ArrowRight } from 'lucide-react';

export function Services() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  const services = [
    {
      icon: Cloud,
      title: "Cloud Services",
      description: "Transform your infrastructure with our cloud expertise",
      features: [
        "Cloud migration & strategy",
        "Multi-cloud solutions",
        "Cloud security",
        "Infrastructure optimization",
        "24/7 cloud management"
      ],
      link: "/services/cloud"
    },
    {
      icon: Shield,
      title: "Security Services",
      description: "Protect your organization with enterprise security",
      features: [
        "Cybersecurity solutions",
        "Compliance management",
        "Security audits",
        "Incident response",
        "Security training"
      ],
      link: "/services/security"
    },
    {
      icon: Monitor,
      title: "Digital Workplace",
      description: "Modern workplace transformation and software engineering",
      features: [
        "Custom software development",
        "Full-stack web applications",
        "Mobile app development",
        "Digital workplace solutions",
        "Enterprise software engineering"
      ],
      link: "/services/digital-workplace"
    },
    {
      icon: Brain,
      title: "AI & Automation",
      description: "Streamline operations with AI-powered solutions",
      features: [
        "AI implementation",
        "Process automation",
        "Machine learning",
        "Predictive analytics",
        "Workflow optimization"
      ],
      link: "/services/ai-automation"
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      <section className="pt-32 pb-16 sm:pt-40 sm:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 gradient-text">Our Services</h1>
            <p className="text-xl text-gray-400 mb-12">
              Comprehensive IT solutions tailored for governments and businesses
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {services.map((service, index) => (
              <Link 
                key={service.title}
                to={service.link} 
                className="group bg-gray-900/50 backdrop-blur-sm p-8 rounded-lg hover:bg-gray-800/50 transition-all duration-300 animate-on-scroll opacity-0 translate-y-4"
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <div className="flex items-center mb-4">
                  <service.icon className="h-8 w-8 text-purple-500 mr-4 transition-transform duration-300 group-hover:scale-110" />
                  <h2 className="text-2xl font-bold text-white">{service.title}</h2>
                </div>
                <p className="text-gray-400 mb-6">{service.description}</p>
                <ul className="space-y-3">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="text-gray-400 flex items-center">
                      <span className="w-2 h-2 bg-purple-500 rounded-full mr-3" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </Link>
            ))}
          </div>

          <div className="mt-12 text-center animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <Link
              to="/contact"
              className="btn-primary group"
            >
              Book Your Free Consultation
              <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}