import { z } from 'zod';

export const contactFormSchema = z.object({
  firstName: z.string().min(2, 'First name must be at least 2 characters'),
  lastName: z.string().min(2, 'Last name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  phone: z.string().min(10, 'Phone number must be at least 10 digits'),
  state: z.string().min(2, 'Please enter a valid state'),
  companyName: z.string().min(2, 'Company name must be at least 2 characters'),
  discussion: z.string().min(10, 'Please provide more details about what you would like to discuss'),
  emailConsent: z.boolean(),
  phoneConsent: z.boolean()
});

export const blogPostSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  slug: z.string().min(1, 'Slug is required'),
  content: z.string().min(1, 'Content is required'),
  excerpt: z.string().optional(),
  featured_image: z.string().url().optional().or(z.literal('')),
  meta_title: z.string().optional(),
  meta_description: z.string().optional(),
  published: z.boolean(),
  categories: z.array(z.string())
});

export type ContactFormData = z.infer<typeof contactFormSchema>;
export type BlogPostFormData = z.infer<typeof blogPostSchema>;