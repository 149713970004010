import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  FileText, 
  Users, 
  Ticket, 
  BarChart3,
  Settings as SettingsIcon,
  LayoutDashboard,
  Loader2,
  ArrowRight,
  Calendar,
  TrendingUp,
  AlertCircle,
  ArrowLeft
} from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface DashboardStats {
  blogPosts: number;
  activeClients: number;
  openTickets: number;
  totalRevenue: number;
}

interface RecentActivity {
  type: 'ticket' | 'client' | 'blog';
  title: string;
  date: string;
  status?: string;
  priority?: string;
}

const navigation = [
  { name: 'Dashboard', href: '/admin/dashboard', icon: LayoutDashboard },
  { name: 'Blog Posts', href: '/admin/blog', icon: FileText },
  { name: 'Clients', href: '/admin/clients', icon: Users },
  { name: 'Tickets', href: '/admin/tickets', icon: Ticket },
  { name: 'Settings', href: '/admin/settings', icon: SettingsIcon }
];

export function AdminDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<DashboardStats>({
    blogPosts: 0,
    activeClients: 0,
    openTickets: 0,
    totalRevenue: 0
  });
  const [recentActivity, setRecentActivity] = useState<RecentActivity[]>([]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  async function fetchDashboardData() {
    try {
      const [blogPosts, clients, tickets, invoices] = await Promise.all([
        supabase.from('blog_posts').select('id', { count: 'exact' }),
        supabase.from('client_users').select('id', { count: 'exact' }),
        supabase.from('client_tickets').select('id').eq('status', 'open'),
        supabase.from('client_invoices').select('amount').eq('status', 'paid')
      ]);

      const totalRevenue = invoices.data?.reduce((sum, invoice) => sum + invoice.amount, 0) || 0;

      setStats({
        blogPosts: blogPosts.count || 0,
        activeClients: clients.count || 0,
        openTickets: tickets.data?.length || 0,
        totalRevenue
      });

      // Fetch recent activity
      const [recentTickets, recentClients, recentPosts] = await Promise.all([
        supabase
          .from('client_tickets')
          .select('title, created_at, status, priority')
          .order('created_at', { ascending: false })
          .limit(5),
        supabase
          .from('client_users')
          .select('first_name, last_name, created_at')
          .order('created_at', { ascending: false })
          .limit(5),
        supabase
          .from('blog_posts')
          .select('title, created_at')
          .order('created_at', { ascending: false })
          .limit(5)
      ]);

      const activity: RecentActivity[] = [
        ...(recentTickets.data?.map(ticket => ({
          type: 'ticket' as const,
          title: ticket.title,
          date: ticket.created_at,
          status: ticket.status,
          priority: ticket.priority
        })) || []),
        ...(recentClients.data?.map(client => ({
          type: 'client' as const,
          title: `${client.first_name} ${client.last_name}`,
          date: client.created_at
        })) || []),
        ...(recentPosts.data?.map(post => ({
          type: 'blog' as const,
          title: post.title,
          date: post.created_at
        })) || [])
      ].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .slice(0, 10);

      setRecentActivity(activity);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white pt-28 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-purple-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Fixed Header */}
      <div className="fixed top-0 left-0 right-0 z-20 bg-black/95 backdrop-blur-sm border-b border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-400 hover:text-white transition-colors"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back
          </button>
          <h1 className="text-xl font-bold">Admin Dashboard</h1>
          <div className="w-20" /> {/* Spacer for balance */}
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="flex">
          {/* Sidebar */}
          <div className="w-64 mr-8 fixed left-4 top-20">
            <nav className="space-y-1">
              {navigation.map((item) => {
                const isActive = location.pathname === item.href;
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`flex items-center px-4 py-3 rounded-lg transition-colors ${
                      isActive 
                        ? 'bg-purple-600 text-white' 
                        : 'text-gray-400 hover:text-white hover:bg-gray-900'
                    }`}
                  >
                    <item.icon className="h-5 w-5 mr-3" />
                    {item.name}
                  </Link>
                );
              })}
            </nav>
          </div>

          {/* Main Content Area */}
          <div className="flex-1 ml-64 pl-8">
            {/* Quick Stats */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
              <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <FileText className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-lg font-semibold">Blog Posts</h3>
                </div>
                <p className="text-3xl font-bold">{stats.blogPosts}</p>
              </div>

              <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <Users className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-lg font-semibold">Active Clients</h3>
                </div>
                <p className="text-3xl font-bold">{stats.activeClients}</p>
              </div>

              <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <Ticket className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-lg font-semibold">Open Tickets</h3>
                </div>
                <p className="text-3xl font-bold">{stats.openTickets}</p>
              </div>

              <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <BarChart3 className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-lg font-semibold">Total Revenue</h3>
                </div>
                <p className="text-3xl font-bold">
                  ${stats.totalRevenue.toLocaleString()}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Recent Activity */}
              <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold">Recent Activity</h2>
                  <Calendar className="h-5 w-5 text-purple-500" />
                </div>

                <div className="space-y-4">
                  {recentActivity.map((activity, index) => (
                    <div key={index} className="flex items-start">
                      {activity.type === 'ticket' && (
                        <Ticket className="h-5 w-5 text-purple-500 mt-1 mr-3" />
                      )}
                      {activity.type === 'client' && (
                        <Users className="h-5 w-5 text-purple-500 mt-1 mr-3" />
                      )}
                      {activity.type === 'blog' && (
                        <FileText className="h-5 w-5 text-purple-500 mt-1 mr-3" />
                      )}
                      <div>
                        <p className="text-sm text-gray-400">
                          {new Date(activity.date).toLocaleDateString()}
                        </p>
                        <p className="font-medium">{activity.title}</p>
                        {activity.type === 'ticket' && activity.status && (
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1
                            ${activity.status === 'resolved' ? 'bg-green-100 text-green-800' :
                              activity.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                              activity.status === 'open' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-gray-100 text-gray-800'}`}>
                            {activity.status}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Quick Actions */}
              <div className="space-y-6">
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h2 className="text-xl font-bold">Quick Actions</h2>
                    <TrendingUp className="h-5 w-5 text-purple-500" />
                  </div>

                  <div className="grid gap-4">
                    <Link 
                      to="/admin/blog/new"
                      className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors"
                    >
                      <div className="flex items-center">
                        <FileText className="h-5 w-5 text-purple-500 mr-3" />
                        <div>
                          <h3 className="font-semibold">Create Blog Post</h3>
                          <p className="text-sm text-gray-400">Write and publish a new blog post</p>
                        </div>
                      </div>
                      <ArrowRight className="h-5 w-5 text-gray-400" />
                    </Link>

                    <Link 
                      to="/admin/tickets"
                      className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors"
                    >
                      <div className="flex items-center">
                        <Ticket className="h-5 w-5 text-purple-500 mr-3" />
                        <div>
                          <h3 className="font-semibold">View Support Tickets</h3>
                          <p className="text-sm text-gray-400">Manage and respond to support tickets</p>
                        </div>
                      </div>
                      <ArrowRight className="h-5 w-5 text-gray-400" />
                    </Link>

                    <Link 
                      to="/admin/clients"
                      className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors"
                    >
                      <div className="flex items-center">
                        <Users className="h-5 w-5 text-purple-500 mr-3" />
                        <div>
                          <h3 className="font-semibold">Manage Clients</h3>
                          <p className="text-sm text-gray-400">View and manage client accounts</p>
                        </div>
                      </div>
                      <ArrowRight className="h-5 w-5 text-gray-400" />
                    </Link>
                  </div>
                </div>

                {/* System Status */}
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h2 className="text-xl font-bold">System Status</h2>
                    <AlertCircle className="h-5 w-5 text-purple-500" />
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <span className="text-gray-400">API Status</span>
                      <span className="flex items-center text-green-500">
                        <span className="h-2 w-2 bg-green-500 rounded-full mr-2" />
                        Operational
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-gray-400">Database Status</span>
                      <span className="flex items-center text-green-500">
                        <span className="h-2 w-2 bg-green-500 rounded-full mr-2" />
                        Operational
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-gray-400">Storage Status</span>
                      <span className="flex items-center text-green-500">
                        <span className="h-2 w-2 bg-green-500 rounded-full mr-2" />
                        Operational
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}