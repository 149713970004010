import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, 
  Filter, 
  Clock,
  AlertCircle,
  CheckCircle2,
  MessageSquare
} from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface Ticket {
  id: string;
  user_id: string;
  subject: string;
  message: string;
  priority: string;
  category: string;
  status: string;
  created_at: string;
  client: {
    first_name: string;
    last_name: string;
    company_name: string;
  } | null;
}

export function AdminTicketList() {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [priorityFilter, setPriorityFilter] = useState('all');

  useEffect(() => {
    fetchTickets();
  }, []);

  async function fetchTickets() {
    try {
      const { data, error } = await supabase
        .from('support_tickets')
        .select(`
          *,
          client:client_users(first_name, last_name, company_name)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setTickets(data || []);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
  }

  const filteredTickets = tickets.filter(ticket => {
    const matchesSearch = 
      ticket.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.message.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesStatus = statusFilter === 'all' || ticket.status === statusFilter;
    const matchesPriority = priorityFilter === 'all' || ticket.priority === priorityFilter;

    return matchesSearch && matchesStatus && matchesPriority;
  });

  return (
    <div className="min-h-screen bg-black text-white pt-28">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Support Tickets</h1>
        </div>

        {/* Search and Filters */}
        <div className="mb-8 space-y-4">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search tickets..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg pl-12 pr-4 py-3 text-white placeholder-gray-400 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          <div className="flex items-center space-x-4">
            <Filter className="h-5 w-5 text-gray-400" />
            <div className="space-x-2">
              {['all', 'open', 'in_progress', 'resolved', 'closed'].map((status) => (
                <button
                  key={status}
                  onClick={() => setStatusFilter(status)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    statusFilter === status
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-900 text-gray-400 hover:bg-gray-800'
                  }`}
                >
                  {status === 'all' ? 'All Status' : status.charAt(0).toUpperCase() + status.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <Filter className="h-5 w-5 text-gray-400" />
            <div className="space-x-2">
              {['all', 'low', 'medium', 'high', 'urgent'].map((priority) => (
                <button
                  key={priority}
                  onClick={() => setPriorityFilter(priority)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    priorityFilter === priority
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-900 text-gray-400 hover:bg-gray-800'
                  }`}
                >
                  {priority === 'all' ? 'All Priority' : priority.charAt(0).toUpperCase() + priority.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Tickets List */}
        <div className="grid gap-6">
          {loading ? (
            [...Array(3)].map((_, index) => (
              <div key={index} className="animate-pulse bg-gray-900/50 rounded-lg p-6">
                <div className="h-6 bg-gray-800 rounded w-1/3 mb-4" />
                <div className="h-4 bg-gray-800 rounded w-2/3" />
              </div>
            ))
          ) : filteredTickets.length > 0 ? (
            filteredTickets.map(ticket => (
              <Link
                key={ticket.id}
                to={`/admin/tickets/${ticket.id}`}
                className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6 hover:bg-gray-800/50 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <div className="flex items-center space-x-3 mb-2">
                      {ticket.priority === 'urgent' ? (
                        <AlertCircle className="h-6 w-6 text-red-500" />
                      ) : ticket.status === 'resolved' ? (
                        <CheckCircle2 className="h-6 w-6 text-green-500" />
                      ) : (
                        <Clock className="h-6 w-6 text-yellow-500" />
                      )}
                      <h3 className="font-semibold">{ticket.subject}</h3>
                      <span className={`text-sm ${
                        ticket.priority === 'urgent' ? 'text-red-500' :
                        ticket.priority === 'high' ? 'text-orange-500' :
                        ticket.priority === 'medium' ? 'text-yellow-500' :
                        'text-blue-500'
                      }`}>
                        {ticket.priority.charAt(0).toUpperCase() + ticket.priority.slice(1)} Priority
                      </span>
                    </div>

                    <p className="text-sm text-gray-400 mb-4">{ticket.message}</p>

                    {ticket.client && (
                      <div className="text-sm text-gray-400">
                        From: {ticket.client.first_name} {ticket.client.last_name} ({ticket.client.company_name})
                      </div>
                    )}

                    <div className="flex items-center space-x-4 text-sm text-gray-400 mt-4">
                      <span className="flex items-center">
                        <Clock className="h-4 w-4 mr-1" />
                        {new Date(ticket.created_at).toLocaleDateString()}
                      </span>
                      <span className="flex items-center">
                        <MessageSquare className="h-4 w-4 mr-1" />
                        3 Comments
                      </span>
                    </div>
                  </div>

                  <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${ticket.status === 'resolved' ? 'bg-green-100 text-green-800' :
                      ticket.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                      ticket.status === 'open' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-gray-100 text-gray-800'}`}>
                    {ticket.status.replace('_', ' ')}
                  </span>
                </div>
              </Link>
            ))
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-400">No tickets found matching your criteria.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}