import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ArrowLeft, Loader2, Save } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type { ClientProject } from '../../lib/types';

const ticketSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  priority: z.enum(['low', 'medium', 'high', 'urgent']),
  project_id: z.string().optional(),
  status: z.enum(['open', 'in_progress', 'resolved', 'closed'])
});

type TicketFormData = z.infer<typeof ticketSchema>;

export function TicketForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [projects, setProjects] = useState<ClientProject[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<TicketFormData>({
    resolver: zodResolver(ticketSchema),
    defaultValues: {
      status: 'open',
      priority: 'medium'
    }
  });

  useEffect(() => {
    fetchProjects();
    if (id) {
      fetchTicket(id);
    }
  }, [id]);

  async function fetchProjects() {
    try {
      const { data, error } = await supabase
        .from('client_projects')
        .select('*')
        .order('name');

      if (error) throw error;
      setProjects(data || []);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }

  async function fetchTicket(ticketId: string) {
    try {
      const { data, error } = await supabase
        .from('client_tickets')
        .select('*')
        .eq('id', ticketId)
        .single();

      if (error) throw error;
      if (data) {
        reset({
          title: data.title,
          description: data.description,
          priority: data.priority,
          project_id: data.project_id || undefined,
          status: data.status
        });
      }
    } catch (error) {
      console.error('Error fetching ticket:', error);
      setError('Failed to load ticket');
    }
  }

  const onSubmit = async (data: TicketFormData) => {
    setLoading(true);
    setError(null);

    try {
      const user = (await supabase.auth.getUser()).data.user;
      if (!user) throw new Error('No user found');

      if (id) {
        // Update existing ticket
        const { error } = await supabase
          .from('client_tickets')
          .update({
            title: data.title,
            description: data.description,
            priority: data.priority,
            project_id: data.project_id || null,
            status: data.status,
            updated_at: new Date().toISOString()
          })
          .eq('id', id);

        if (error) throw error;
      } else {
        // Create new ticket
        const { error } = await supabase
          .from('client_tickets')
          .insert([{
            title: data.title,
            description: data.description,
            priority: data.priority,
            project_id: data.project_id || null,
            status: data.status,
            organization_id: user.id,
            created_by: user.id
          }]);

        if (error) throw error;
      }

      navigate('/client/tickets');
    } catch (error) {
      console.error('Error saving ticket:', error);
      setError('Failed to save ticket');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white pt-28">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <button
          onClick={() => navigate('/client/tickets')}
          className="flex items-center text-purple-500 hover:text-purple-400 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Tickets
        </button>

        <h1 className="text-3xl font-bold mb-8">
          {id ? 'Edit Ticket' : 'New Support Ticket'}
        </h1>

        {error && (
          <div className="mb-6 p-4 bg-red-900/50 border border-red-500/20 rounded-lg text-red-400">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-white mb-2">
              Title
            </label>
            <input
              type="text"
              {...register('title')}
              className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-500">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-white mb-2">
              Description
            </label>
            <textarea
              {...register('description')}
              rows={4}
              className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-500">{errors.description.message}</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-white mb-2">
                Priority
              </label>
              <select
                {...register('priority')}
                className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-white mb-2">
                Related Project
              </label>
              <select
                {...register('project_id')}
                className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              >
                <option value="">None</option>
                {projects.map(project => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {id && (
            <div>
              <label className="block text-sm font-medium text-white mb-2">
                Status
              </label>
              <select
                {...register('status')}
                className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              >
                <option value="open">Open</option>
                <option value="in_progress">In Progress</option>
                <option value="resolved">Resolved</option>
                <option value="closed">Closed</option>
              </select>
            </div>
          )}

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="btn-primary"
            >
              {loading ? (
                <>
                  <Loader2 className="animate-spin h-5 w-5 mr-2" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-5 w-5 mr-2" />
                  {id ? 'Update Ticket' : 'Create Ticket'}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}