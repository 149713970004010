import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { Home } from './components/pages/Home';
import { Contact } from './components/pages/Contact';
import { Services } from './components/pages/Services';
import { About } from './components/pages/About';
import { FoundersLetter } from './components/pages/FoundersLetter';
import { Privacy } from './components/pages/Privacy';
import { Terms } from './components/pages/Terms';
import { CloudServices } from './components/services/CloudServices';
import { SecurityServices } from './components/services/SecurityServices';
import { DigitalWorkplace } from './components/services/DigitalWorkplace';
import { AIAutomation } from './components/services/AIAutomation';
import { Blog } from './components/pages/Blog';
import { BlogPost } from './components/pages/BlogPost';
import { BlogAdmin } from './components/admin/BlogAdmin';
import { BlogPostForm } from './components/admin/BlogPostForm';
import { CaseStudies } from './components/pages/CaseStudies';
import { ClientDashboard } from './components/client/ClientDashboard';
import { ProjectList } from './components/client/ProjectList';
import { ProjectForm } from './components/client/ProjectForm';
import { DocumentList } from './components/client/DocumentList';
import { TicketList } from './components/client/TicketList';
import { TicketForm } from './components/client/TicketForm';
import { LoginPage } from './components/auth/LoginPage';
import { RegisterPage } from './components/auth/RegisterPage';
import { VerifyEmailPage } from './components/auth/VerifyEmailPage';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { ProtectedAdminRoute } from './components/auth/ProtectedAdminRoute';
import { Profile } from './components/client/Profile';
import { Settings } from './components/client/Settings';
import { Support } from './components/client/Support';
import { AdminDashboard } from './components/admin/AdminDashboard';
import { AdminClientList } from './components/admin/AdminClientList';
import { AdminTicketList } from './components/admin/AdminTicketList';

// Component to handle external redirects
const ExternalRedirect = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
};

function App() {
  const location = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location.pathname]);

  return (
    <div className="min-h-screen bg-white">
      <Header />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/founders-letter" element={<FoundersLetter />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/services/cloud" element={<CloudServices />} />
        <Route path="/services/security" element={<SecurityServices />} />
        <Route path="/services/digital-workplace" element={<DigitalWorkplace />} />
        <Route path="/services/ai-automation" element={<AIAutomation />} />
        <Route path="/support" element={<Support />} />

        {/* Substack Redirects */}
        <Route path="/blog" element={<ExternalRedirect to="https://robavo.substack.com" />} />
        <Route path="/blog/:slug" element={<ExternalRedirect to="https://robavo.substack.com" />} />
        <Route path="/case-studies" element={<ExternalRedirect to="https://robavo.substack.com" />} />

        {/* Auth Routes */}
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/register" element={<RegisterPage />} />
        <Route path="/auth/verify-email" element={<VerifyEmailPage />} />

        {/* Protected Client Routes */}
        <Route path="/client" element={<ProtectedRoute><ClientDashboard /></ProtectedRoute>} />
        <Route path="/client/dashboard" element={<ProtectedRoute><ClientDashboard /></ProtectedRoute>} />
        <Route path="/client/projects" element={<ProtectedRoute><ProjectList /></ProtectedRoute>} />
        <Route path="/client/projects/new" element={<ProtectedRoute><ProjectForm /></ProtectedRoute>} />
        <Route path="/client/projects/:id" element={<ProtectedRoute><ProjectForm /></ProtectedRoute>} />
        <Route path="/client/documents" element={<ProtectedRoute><DocumentList /></ProtectedRoute>} />
        <Route path="/client/projects/:projectId/documents" element={<ProtectedRoute><DocumentList /></ProtectedRoute>} />
        <Route path="/client/tickets" element={<ProtectedRoute><TicketList /></ProtectedRoute>} />
        <Route path="/client/tickets/new" element={<ProtectedRoute><TicketForm /></ProtectedRoute>} />
        <Route path="/client/tickets/:id" element={<ProtectedRoute><TicketForm /></ProtectedRoute>} />
        <Route path="/client/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/client/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />

        {/* Protected Admin Routes */}
        <Route path="/admin" element={<ProtectedAdminRoute><AdminDashboard /></ProtectedAdminRoute>} />
        <Route path="/admin/dashboard" element={<ProtectedAdminRoute><AdminDashboard /></ProtectedAdminRoute>} />
        <Route path="/admin/blog" element={<ProtectedAdminRoute><BlogAdmin /></ProtectedAdminRoute>} />
        <Route path="/admin/blog/new" element={<ProtectedAdminRoute><BlogPostForm /></ProtectedAdminRoute>} />
        <Route path="/admin/blog/edit/:id" element={<ProtectedAdminRoute><BlogPostForm /></ProtectedAdminRoute>} />
        <Route path="/admin/clients" element={<ProtectedAdminRoute><AdminClientList /></ProtectedAdminRoute>} />
        <Route path="/admin/tickets" element={<ProtectedAdminRoute><AdminTicketList /></ProtectedAdminRoute>} />

        {/* Cortex Admin Route */}
        <Route path="/cortex/view" element={<ProtectedAdminRoute><AdminDashboard /></ProtectedAdminRoute>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;