import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export function Terms() {
  return (
    <div className="pt-32 pb-16 sm:pt-40 sm:pb-24 bg-black text-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/" className="inline-flex items-center text-purple-600 hover:text-purple-700">
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Home
          </Link>
        </div>

        <h1 className="text-4xl md:text-5xl font-bold mb-8">Terms of Service</h1>
        
        <div className="prose prose-invert max-w-none">
          <p className="text-gray-400 mb-8">
            Last updated: March 15, 2024
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">1. Agreement to Terms</h2>
          <p className="text-gray-400 mb-6">
            By accessing or using Robavo Consulting's services, you agree to be bound by these Terms of Service. 
            If you disagree with any part of these terms, you may not access our services.
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">2. Services</h2>
          <p className="text-gray-400 mb-6">
            Robavo Consulting provides IT consulting services, including but not limited to:
          </p>
          <ul className="list-disc pl-6 text-gray-400 mb-6">
            <li>Cloud services and migration</li>
            <li>Security services</li>
            <li>Digital workplace solutions</li>
            <li>AI and automation services</li>
          </ul>

          <h2 className="text-2xl font-bold mt-12 mb-4">3. Service Level Agreements</h2>
          <p className="text-gray-400 mb-6">
            Specific service levels, response times, and performance metrics will be detailed in individual 
            service agreements. These agreements will be provided and must be signed before service delivery begins.
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">4. Client Responsibilities</h2>
          <p className="text-gray-400 mb-6">
            Clients agree to:
          </p>
          <ul className="list-disc pl-6 text-gray-400 mb-6">
            <li>Provide accurate and complete information</li>
            <li>Maintain security of access credentials</li>
            <li>Comply with reasonable security practices</li>
            <li>Pay for services as agreed</li>
          </ul>

          <h2 className="text-2xl font-bold mt-12 mb-4">5. Intellectual Property</h2>
          <p className="text-gray-400 mb-6">
            All intellectual property rights in our services remain with Robavo Consulting. Clients receive 
            a limited license to use deliverables as specified in service agreements.
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">6. Limitation of Liability</h2>
          <p className="text-gray-400 mb-6">
            Robavo Consulting shall not be liable for indirect, incidental, special, consequential, or 
            punitive damages arising out of or relating to these terms or the use of our services.
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">7. Changes to Terms</h2>
          <p className="text-gray-400 mb-6">
            We reserve the right to modify these terms at any time. We will notify clients of any material 
            changes via email or through our website.
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">8. Contact</h2>
          <p className="text-gray-400 mb-6">
            For questions about these Terms of Service, please contact us at:{' '}
            <a href="mailto:legal@robavo.com" className="text-purple-500 hover:text-purple-400">
              legal@robavo.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}