import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Loader2, CheckCircle, AlertCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { contactFormSchema, type ContactFormData } from '../../lib/validation';
import { supabase } from '../../lib/supabase';
import { ErrorBoundary } from '../ErrorBoundary';

const CAL_API_KEY = 'cal_live_102f6a3807970cb44c49080278e54f96';

export function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactFormSchema)
  });

  const onSubmit = async (data: ContactFormData) => {
    setIsSubmitting(true);
    setSubmitStatus(null);
    setErrorMessage('');

    try {
      const { error } = await supabase
        .from('contact_submissions')
        .insert([{
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone: data.phone,
          state: data.state,
          company_name: data.companyName,
          discussion: data.discussion,
          email_consent: data.emailConsent,
          phone_consent: data.phoneConsent
        }]);

      if (error) throw error;

      setSubmitStatus('success');
      reset();

      // Construct Cal.com URL with prefilled information
      const calUrl = new URL('https://cal.com/ovabor/consultation');
      calUrl.searchParams.append('name', `${data.firstName} ${data.lastName}`);
      calUrl.searchParams.append('email', data.email);
      calUrl.searchParams.append('notes', data.discussion);
      calUrl.searchParams.append('location', data.state);
      calUrl.searchParams.append('guests[0]', data.email);

      // Add API key as a custom header
      const headers = new Headers({
        'Authorization': `Bearer ${CAL_API_KEY}`,
        'Content-Type': 'application/json'
      });

      // Open Cal.com booking page in a new tab
      window.open(calUrl.toString(), '_blank');
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ErrorBoundary>
      <div className="pt-32 pb-16 sm:pt-40 sm:pb-24 bg-black">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-8">
            <Link to="/" className="inline-flex items-center text-purple-600 hover:text-purple-700 transition-colors group">
              <ArrowLeft className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:-translate-x-1" />
              Back to Home
            </Link>
          </div>
          
          <div className="bg-gray-900 rounded-lg p-8">
            <h1 className="text-4xl font-bold text-white mb-8">Book Your Free Consultation</h1>
            
            {submitStatus === 'success' && (
              <div className="mb-6 p-4 bg-green-900/50 border border-green-500/20 rounded-lg flex items-start">
                <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 mr-3 flex-shrink-0" />
                <div>
                  <p className="text-green-300 font-medium">Thank you for your interest!</p>
                  <p className="text-green-400 text-sm mt-1">
                    Your form has been submitted successfully. You'll be redirected to our calendar to schedule your consultation.
                  </p>
                </div>
              </div>
            )}

            {submitStatus === 'error' && (
              <div className="mb-6 p-4 bg-red-900/50 border border-red-500/20 rounded-lg flex items-start">
                <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 mr-3 flex-shrink-0" />
                <div>
                  <p className="text-red-300 font-medium">Something went wrong</p>
                  <p className="text-red-400 text-sm mt-1">
                    {errorMessage || 'Please try again or contact us directly at support@robavo.xyz'}
                  </p>
                </div>
              </div>
            )}
            
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="discussion" className="block text-lg font-medium text-white mb-2">
                  What would you like to discuss?
                </label>
                <textarea
                  id="discussion"
                  {...register('discussion')}
                  rows={4}
                  className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                    errors.discussion 
                      ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                      : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                  }`}
                />
                {errors.discussion && (
                  <p className="mt-1 text-sm text-red-500">{errors.discussion.message}</p>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-white mb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    {...register('firstName')}
                    className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                      errors.firstName 
                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                        : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                    }`}
                  />
                  {errors.firstName && (
                    <p className="mt-1 text-sm text-red-500">{errors.firstName.message}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-white mb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    {...register('lastName')}
                    className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                      errors.lastName 
                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                        : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                    }`}
                  />
                  {errors.lastName && (
                    <p className="mt-1 text-sm text-red-500">{errors.lastName.message}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-white mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register('email')}
                    className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                      errors.email 
                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                        : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                    }`}
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-white mb-2">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    {...register('phone')}
                    className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                      errors.phone 
                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                        : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                    }`}
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-500">{errors.phone.message}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="state" className="block text-sm font-medium text-white mb-2">
                    State
                  </label>
                  <input
                    type="text"
                    id="state"
                    {...register('state')}
                    className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                      errors.state 
                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                        : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                    }`}
                  />
                  {errors.state && (
                    <p className="mt-1 text-sm text-red-500">{errors.state.message}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="companyName" className="block text-sm font-medium text-white mb-2">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    {...register('companyName')}
                    className={`w-full px-4 py-2 rounded-lg bg-gray-800 text-white border focus:ring-2 transition-colors ${
                      errors.companyName 
                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500/20' 
                        : 'border-gray-700 focus:border-purple-500 focus:ring-purple-500/20'
                    }`}
                  />
                  {errors.companyName && (
                    <p className="mt-1 text-sm text-red-500">{errors.companyName.message}</p>
                  )}
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      id="emailConsent"
                      {...register('emailConsent')}
                      className="h-4 w-4 rounded border-gray-700 text-purple-600 focus:ring-purple-500 bg-gray-800"
                    />
                  </div>
                  <label htmlFor="emailConsent" className="ml-3 text-sm text-gray-400">
                    I allow Robavo Consulting to use my contact data and keep me informed of services and offerings by email
                  </label>
                </div>

                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      id="phoneConsent"
                      {...register('phoneConsent')}
                      className="h-4 w-4 rounded border-gray-700 text-purple-600 focus:ring-purple-500 bg-gray-800"
                    />
                  </div>
                  <label htmlFor="phoneConsent" className="ml-3 text-sm text-gray-400">
                    I also allow to be contacted by phone
                  </label>
                </div>
              </div>

              <p className="text-sm text-gray-400">
                Your consent is optional, and you can withdraw it at any time by submitting an opt-out request. 
                Also, you may unsubscribe from receiving marketing emails by clicking the unsubscribe link in each email.
              </p>

              <p className="text-sm text-gray-400">
                By submitting this, you acknowledge you have read and understood the{' '}
                <Link to="/privacy" className="text-purple-500 hover:text-purple-400">
                  Robavo Consulting Privacy Statement
                </Link>
                .
              </p>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full bg-purple-600 text-white px-8 py-4 rounded-lg font-medium transition-all duration-200 
                  ${isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-purple-700'} 
                  flex items-center justify-center`}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5 mr-2" />
                    Submitting...
                  </>
                ) : (
                  'Request Meeting'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}