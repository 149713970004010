import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export function Privacy() {
  return (
    <div className="pt-32 pb-16 sm:pt-40 sm:pb-24 bg-black text-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/" className="inline-flex items-center text-purple-600 hover:text-purple-700">
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Home
          </Link>
        </div>

        <h1 className="text-4xl md:text-5xl font-bold mb-8">Privacy Policy</h1>
        
        <div className="prose prose-invert max-w-none">
          <p className="text-gray-400 mb-8">
            Last updated: March 15, 2024
          </p>

          <h2 className="text-2xl font-bold mt-12 mb-4">1. Information We Collect</h2>
          <p className="text-gray-400 mb-6">
            We collect information that you provide directly to us, including:
          </p>
          <ul className="list-disc pl-6 text-gray-400 mb-6">
            <li>Name and contact information</li>
            <li>Company details</li>
            <li>Communication preferences</li>
            <li>Service inquiries and requirements</li>
          </ul>

          <h2 className="text-2xl font-bold mt-12 mb-4">2. How We Use Your Information</h2>
          <p className="text-gray-400 mb-6">
            We use the information we collect to:
          </p>
          <ul className="list-disc pl-6 text-gray-400 mb-6">
            <li>Provide and improve our services</li>
            <li>Communicate with you about our services</li>
            <li>Send you marketing communications (with your consent)</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h2 className="text-2xl font-bold mt-12 mb-4">3. Information Sharing</h2>
          <p className="text-gray-400 mb-6">
            We do not sell your personal information. We may share your information with:
          </p>
          <ul className="list-disc pl-6 text-gray-400 mb-6">
            <li>Service providers who assist in our operations</li>
            <li>Professional advisors</li>
            <li>Law enforcement when required by law</li>
          </ul>

          <h2 className="text-2xl font-bold mt-12 mb-4">4. Your Rights</h2>
          <p className="text-gray-400 mb-6">
            You have the right to:
          </p>
          <ul className="list-disc pl-6 text-gray-400 mb-6">
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Opt-out of marketing communications</li>
          </ul>

          <h2 className="text-2xl font-bold mt-12 mb-4">5. Contact Us</h2>
          <p className="text-gray-400 mb-6">
            If you have questions about this Privacy Policy, please contact us at:{' '}
            <a href="mailto:privacy@robavo.com" className="text-purple-500 hover:text-purple-400">
              privacy@robavo.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}