import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { 
  Award, 
  GraduationCap, 
  Shield, 
  Cloud, 
  ArrowRight, 
  CheckCircle, 
  Building2, 
  Users,
  Target,
  FileCheck
} from 'lucide-react';

export function About() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero Section */}
      <section className="pt-32 pb-16 sm:pt-40 sm:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">About Robavo Consulting</h1>
            <p className="text-xl text-gray-400 max-w-3xl">
              A trusted partner in IT solutions for governments and businesses since 2022, delivering excellence through innovation and expertise.
            </p>
          </div>
        </div>
      </section>

      {/* Company Overview */}
      <section className="py-16 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-12">
            <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
              <h2 className="text-3xl font-bold mb-6">Our Mission</h2>
              <p className="text-gray-400 mb-6">
                At Robavo Consulting, we're committed to transforming organizations through innovative IT solutions. Our mission is to empower governments and businesses with secure, efficient, and scalable technology infrastructure.
              </p>
              <div className="space-y-4">
                {[
                  "Delivering exceptional value through tailored IT solutions",
                  "Maintaining the highest standards of security and compliance",
                  "Fostering long-term partnerships with our clients",
                  "Driving digital transformation and innovation"
                ].map((item, index) => (
                  <div key={index} className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-purple-500 mt-1 flex-shrink-0" />
                    <span className="ml-3 text-gray-400">{item}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700 delay-100">
              <h2 className="text-3xl font-bold mb-6">Company Facts</h2>
              <div className="grid grid-cols-2 gap-6">
                <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
                  <Building2 className="h-8 w-8 text-purple-500 mb-4" />
                  <h3 className="text-xl font-bold mb-2">Founded</h3>
                  <p className="text-gray-400">2022 in Wyoming</p>
                </div>
                <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
                  <Users className="h-8 w-8 text-purple-500 mb-4" />
                  <h3 className="text-xl font-bold mb-2">Team</h3>
                  <p className="text-gray-400">Expert IT Professionals</p>
                </div>
                <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
                  <Target className="h-8 w-8 text-purple-500 mb-4" />
                  <h3 className="text-xl font-bold mb-2">Focus</h3>
                  <p className="text-gray-400">Government & Enterprise</p>
                </div>
                <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg">
                  <FileCheck className="h-8 w-8 text-purple-500 mb-4" />
                  <h3 className="text-xl font-bold mb-2">Compliance</h3>
                  <p className="text-gray-400">HIPAA, GDPR, SOC 2</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="py-16 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h2 className="text-3xl font-bold mb-12 text-center">Our Expertise</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
                <div className="flex items-center mb-4">
                  <Award className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-xl font-semibold">Experience</h3>
                </div>
                <ul className="space-y-2 text-gray-400">
                  <li>• 5+ years in IT consulting</li>
                  <li>• Government sector expertise</li>
                  <li>• Enterprise solutions</li>
                  <li>• Digital transformation</li>
                </ul>
              </div>

              <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
                <div className="flex items-center mb-4">
                  <GraduationCap className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-xl font-semibold">Education</h3>
                </div>
                <ul className="space-y-2 text-gray-400">
                  <li>• Computer Science</li>
                  <li>• Cybersecurity</li>
                  <li>• Cloud Architecture</li>
                  <li>• Software Engineering</li>
                </ul>
              </div>

              <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
                <div className="flex items-center mb-4">
                  <Shield className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-xl font-semibold">Certifications</h3>
                </div>
                <ul className="space-y-2 text-gray-400">
                  <li>• CompTIA Security+</li>
                  <li>• AWS Certified</li>
                  <li>• Azure Expert</li>
                  <li>• CISSP</li>
                </ul>
              </div>

              <div className="bg-gray-900/50 backdrop-blur-sm p-6 rounded-lg transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
                <div className="flex items-center mb-4">
                  <Cloud className="h-6 w-6 text-purple-500 mr-3" />
                  <h3 className="text-xl font-semibold">Technologies</h3>
                </div>
                <ul className="space-y-2 text-gray-400">
                  <li>• Cloud Platforms</li>
                  <li>• AI & Machine Learning</li>
                  <li>• DevSecOps</li>
                  <li>• Enterprise Software</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Compliance & Standards */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h2 className="text-3xl font-bold mb-12 text-center">Compliance & Standards</h2>
            <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-8">
              <div className="grid md:grid-cols-3 gap-8">
                <div>
                  <h3 className="text-xl font-bold mb-4">Security Standards</h3>
                  <ul className="space-y-2 text-gray-400">
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      NIST Framework
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      ISO 27001
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      SOC 2 Type II
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-bold mb-4">Compliance</h3>
                  <ul className="space-y-2 text-gray-400">
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      HIPAA
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      GDPR
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      CCPA
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-bold mb-4">Best Practices</h3>
                  <ul className="space-y-2 text-gray-400">
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      ITIL Framework
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      DevSecOps
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                      Agile Methodology
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-gradient-to-b from-gray-900 to-black">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h2 className="text-3xl font-bold mb-6">Ready to Work Together?</h2>
            <p className="text-xl text-gray-400 mb-8">
              Let's discuss how we can help you achieve your technology goals.
            </p>
            <div className="space-x-4">
              <Link to="/contact" className="btn-primary group">
                Book Your Free Consultation
                <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link to="/founders-letter" className="btn-secondary">
                Read Our Founder's Letter
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}