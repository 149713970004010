import React from 'react';
import { ArrowRight, CheckCircle, Download, Quote } from 'lucide-react';

interface CaseStudyProps {
  industry: string;
  challenge: string;
  solution: string;
  results: string[];
  technologies: string[];
  testimonial?: {
    quote: string;
    author: string;
    title: string;
  };
  featured?: boolean;
}

export function CaseStudy({ 
  industry, 
  challenge, 
  solution, 
  results, 
  technologies,
  testimonial,
  featured = false 
}: CaseStudyProps) {
  return (
    <div className={`
      bg-gray-900/50 backdrop-blur-sm rounded-lg p-8 
      animate-on-scroll opacity-0 translate-y-4 transition-all duration-700
      ${featured ? 'border-2 border-purple-500/20' : ''}
    `}>
      <div className="mb-6">
        {featured && (
          <span className="bg-purple-500 text-white text-xs font-medium px-2.5 py-1 rounded-full mb-2 inline-block">
            Featured Case Study
          </span>
        )}
        <span className="text-purple-500 text-sm font-medium">Industry</span>
        <h3 className="text-2xl font-bold text-white mt-1">{industry}</h3>
      </div>

      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold text-white mb-2">Challenge</h4>
          <p className="text-gray-400">{challenge}</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold text-white mb-2">Solution</h4>
          <p className="text-gray-400">{solution}</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold text-white mb-2">Results</h4>
          <ul className="space-y-2">
            {results.map((result, index) => (
              <li key={index} className="flex items-start">
                <CheckCircle className="h-5 w-5 text-purple-500 mt-1 mr-3 flex-shrink-0" />
                <span className="text-gray-400">{result}</span>
              </li>
            ))}
          </ul>
        </div>

        {testimonial && (
          <div className="bg-purple-600/10 rounded-lg p-6 border border-purple-500/20">
            <Quote className="h-8 w-8 text-purple-500 mb-4" />
            <blockquote className="text-gray-300 italic mb-4">
              "{testimonial.quote}"
            </blockquote>
            <div className="text-sm">
              <div className="font-medium text-white">{testimonial.author}</div>
              <div className="text-gray-400">{testimonial.title}</div>
            </div>
          </div>
        )}

        <div>
          <h4 className="text-lg font-semibold text-white mb-2">Technologies Used</h4>
          <div className="flex flex-wrap gap-2">
            {technologies.map((tech, index) => (
              <span
                key={index}
                className="bg-purple-600/10 text-purple-400 px-3 py-1 rounded-full text-sm"
              >
                {tech}
              </span>
            ))}
          </div>
        </div>

        <div className="pt-4 flex justify-between items-center">
          <button 
            className="inline-flex items-center text-purple-500 hover:text-purple-400 transition-colors text-sm"
            onClick={() => {
              // Generate PDF logic would go here
              console.log('Downloading case study...');
            }}
          >
            <Download className="h-4 w-4 mr-2" />
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
}