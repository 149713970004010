import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Brain, Cpu, Workflow, Bot } from 'lucide-react';
import { CaseStudy } from '../CaseStudy';

export function AIAutomation() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <section className="pt-32 pb-16 sm:pt-40 sm:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="mb-8 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <Link to="/services" className="inline-flex items-center text-purple-500 hover:text-purple-400 transition-colors group">
              <ArrowLeft className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:-translate-x-1" />
              Back to Services
            </Link>
          </div>

          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">AI & Automation</h1>
            <p className="text-xl text-gray-400 mb-12 max-w-3xl">
              Transform your business operations with cutting-edge AI solutions and intelligent automation.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">AI Solutions</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Advanced AI solutions for your business:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Custom AI model development",
                      "Natural language processing",
                      "Computer vision solutions",
                      "Predictive analytics",
                      "Machine learning implementation",
                      "AI-powered chatbots",
                      "Data analysis and insights",
                      "AI strategy consulting"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Brain className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Process Automation</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Streamline operations with intelligent automation:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Robotic Process Automation (RPA)",
                      "Workflow automation",
                      "Business process optimization",
                      "Document processing",
                      "Task automation",
                      "Integration automation",
                      "Custom automation solutions"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Workflow className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Intelligent Systems</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Smart solutions for modern businesses:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Intelligent decision support",
                      "Smart monitoring systems",
                      "Automated reporting",
                      "Pattern recognition",
                      "Anomaly detection",
                      "Process optimization",
                      "Performance analytics",
                      "AI-driven insights"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Cpu className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <div className="bg-purple-600/10 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20">
                  <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Operations?</h2>
                  <p className="text-gray-400 mb-6">
                    Book a free consultation with our AI experts and discover how we can help automate and optimize your business processes.
                  </p>
                  <Link to="/contact" className="btn-primary group">
                    Book Your Free Consultation
                    <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Case Studies Section */}
          <div className="mt-24">
            <h2 className="text-2xl font-bold mb-12">Client Success Stories</h2>
            <div className="grid lg:grid-cols-2 gap-8">
              <CaseStudy
                industry="Enterprise AI"
                challenge="A Fortune 500 company needed to automate document processing and knowledge management while ensuring data privacy and security."
                solution="Developed a custom LLM using their proprietary data with RAG technology, implementing strict security controls and data governance. Built intelligent agents for automated document processing and knowledge synthesis."
                results={[
                  "90% reduction in document processing time",
                  "100% compliance with data privacy requirements",
                  "Improved knowledge discovery by 75%",
                  "Saved 2000+ hours per month in manual work"
                ]}
                technologies={[
                  "Azure OpenAI", "LangChain",
                  "Vector DB", "RAG Pipeline",
                  "Custom LLM", "Azure Cognitive Search"
                ]}
                testimonial={{
                  quote: "Robavo's AI solution transformed our document processing workflow. Their attention to security and compliance while delivering cutting-edge AI capabilities was impressive.",
                  author: "Sarah Chen",
                  title: "Chief Technology Officer"
                }}
              />

              <CaseStudy
                industry="AI Security"
                challenge="A financial institution needed to leverage GenAI while preventing sensitive data leakage and ensuring secure model deployment."
                solution="Implemented a comprehensive GenAI security framework with secure prompt engineering, data filtering, and automated security scanning. Developed multimodal agents for secure document analysis."
                results={[
                  "Zero sensitive data exposure",
                  "Reduced false positives by 85%",
                  "Automated 60% of compliance checks",
                  "Secure deployment of 50+ AI models"
                ]}
                technologies={[
                  "Google AgentSpace", "Salesforce AgentForce",
                  "Azure Cognitive Services", "Custom Security Layers",
                  "Prompt Security Framework"
                ]}
                testimonial={{
                  quote: "Robavo's approach to secure AI implementation has allowed us to innovate while maintaining strict data protection standards.",
                  author: "Robert Chang",
                  title: "AI Security Lead"
                }}
              />
            </div>
            
            <div className="mt-12 text-center">
              <Link to="/case-studies" className="btn-secondary group">
                View All Case Studies
                <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}