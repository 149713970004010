import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export function FoundersLetter() {
  return (
    <div className="pt-32 pb-16 sm:pt-40 sm:pb-24 bg-black text-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/about" className="inline-flex items-center text-purple-600 hover:text-purple-700">
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to About
          </Link>
        </div>

        <h1 className="text-4xl md:text-5xl font-bold mb-8">A Letter from Our Founder</h1>
        
        <div className="prose prose-invert max-w-none">
          <p className="text-xl text-gray-400 mb-8">
            Dear Friends, Partners, and Future Clients,
          </p>

          <p className="text-gray-400 mb-6">
            When I founded Robavo Consulting, I had a clear vision: to bridge the gap between complex IT challenges and elegant, efficient solutions. Having spent years in both government and private sector IT, I witnessed firsthand the struggles organizations face when trying to modernize their infrastructure while maintaining security and compliance.
          </p>

          <p className="text-gray-400 mb-6">
            It is with great enthusiasm that I introduce you to Robavo Consulting, a company built to help businesses and governments navigate the complex world of technology with clarity, purpose, and measurable results. Our mission is simple but ambitious: to empower organizations with tailored IT solutions that reduce costs, increase efficiency, and enhance security.
          </p>

          <p className="text-gray-400 mb-6">
            As the founder, I created Robavo Consulting to address a need I have witnessed time and again—a need for technology services that are both deeply customized and outcomes-driven. Whether it is designing secure cloud environments, enabling remote workforces, or implementing AI solutions that save time and resources, our work is grounded in an understanding of what truly matters to you. Success is not just about using technology; it is about achieving your goals through technology.
          </p>

          <p className="text-gray-400 mb-6">
            We offer a range of services to meet your needs. From managing cloud migrations and securing data privacy to providing ongoing IT support and integrating AI into your operations, our team of skilled practitioners brings expertise and dedication to every project. At Robavo Consulting, we understand that each client is unique, and we pride ourselves on crafting solutions that reflect that.
          </p>

          <p className="text-gray-400 mb-6">
            Our approach is rooted in partnership. We listen first, ensuring we understand your challenges and aspirations before we act. Our team is committed to delivering work that is not only technically excellent but also aligned with your specific goals. We measure our success by the tangible improvements we help you achieve, whether that means cost savings, faster processes, or greater peace of mind.
          </p>

          <div className="bg-gray-900 p-6 rounded-lg mb-6">
            <h3 className="text-xl font-bold mb-4">Our Core Values</h3>
            <ul className="space-y-3">
              <li className="text-gray-400">
                <span className="text-white font-semibold">Excellence:</span> We maintain the highest standards in everything we do
              </li>
              <li className="text-gray-400">
                <span className="text-white font-semibold">Trust:</span> Complete transparency in our client communications
              </li>
              <li className="text-gray-400">
                <span className="text-white font-semibold">Precision:</span> Meticulous attention to detail in every implementation
              </li>
              <li className="text-gray-400">
                <span className="text-white font-semibold">Innovation:</span> Continuously exploring new technologies while ensuring stability
              </li>
              <li className="text-gray-400">
                <span className="text-white font-semibold">Partnership:</span> We are fully invested in our clients' success
              </li>
            </ul>
          </div>

          <p className="text-gray-400 mb-6">
            Robavo Consulting operates under Robavo Expeditions LLC, which was proudly registered in Wyoming in 2022 and operates with full compliance under state regulations. We conduct business as Robavo Consulting to better reflect the services we provide to businesses and government clients.
          </p>

          <p className="text-gray-400 mb-6">
            I invite you to learn more about what we can offer and explore how we can work together. Whether you are a small business looking to scale or a government agency seeking reliable IT support, Robavo Consulting is here to help you navigate today's challenges and prepare for tomorrow's opportunities.
          </p>

          <p className="text-gray-400 mb-12">
            Thank you for considering us as a partner in your journey. I look forward to building something exceptional together.
          </p>

          <p className="text-gray-400">
            Warm regards,
            <br />
            <span className="text-white font-bold">Alexander</span>
            <br />
            Founder & Managing Partner, Robavo Expeditions LLC
          </p>
        </div>

        <div className="mt-16 text-center">
          <Link
            to="/contact"
            className="inline-flex items-center bg-purple-600 text-white px-8 py-4 rounded-lg font-medium hover:bg-purple-700 transition-colors"
          >
            Book Your Free Consultation
          </Link>
        </div>
      </div>
    </div>
  );
}