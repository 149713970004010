import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronRight, ArrowRight, Cloud, Shield, Check } from 'lucide-react';
import { CaseStudy } from '../CaseStudy';

export function CloudServices() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <section className="pt-32 pb-16 sm:pt-40 sm:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="mb-8 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <Link to="/services" className="inline-flex items-center text-purple-500 hover:text-purple-400 transition-colors group">
              <ArrowLeft className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:-translate-x-1" />
              Back to Services
            </Link>
          </div>

          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">Cloud Services</h1>
            <p className="text-xl text-gray-400 mb-12 max-w-3xl">
              Transform your business with our comprehensive cloud solutions. We help you migrate, optimize, and manage your cloud infrastructure with zero downtime.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Cloud Migration</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Seamless migration to AWS, Azure, or Google Cloud with our proven methodology:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Assessment of current infrastructure",
                      "Custom migration strategy",
                      "Data transfer and validation",
                      "Post-migration optimization",
                      "24/7 support during migration"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Check className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Cloud Security</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Enterprise-grade security for your cloud infrastructure:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Identity and access management",
                      "Encryption at rest and in transit",
                      "Security monitoring and alerts",
                      "Compliance management",
                      "Regular security audits"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Shield className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Cloud Management</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Comprehensive cloud management services:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "24/7 monitoring and maintenance",
                      "Performance optimization",
                      "Cost management",
                      "Capacity planning",
                      "Backup and disaster recovery"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Cloud className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <div className="bg-purple-600/10 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20">
                  <h2 className="text-2xl font-bold mb-4">Ready to Get Started?</h2>
                  <p className="text-gray-400 mb-6">
                    Book a free consultation with our cloud experts and discover how we can help you transform your infrastructure.
                  </p>
                  <Link to="/contact" className="btn-primary group">
                    Book Your Free Consultation
                    <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Case Studies Section */}
          <div className="mt-24">
            <h2 className="text-2xl font-bold mb-12">Client Success Stories</h2>
            <div className="grid lg:grid-cols-2 gap-8">
              <CaseStudy
                industry="Healthcare Technology"
                challenge="A healthcare provider needed to modernize their infrastructure while maintaining HIPAA compliance and ensuring 24/7 availability of critical systems."
                solution="Implemented a hybrid cloud solution with AWS, featuring automated scaling, disaster recovery, and comprehensive security controls. Utilized AWS Healthcare Competency solutions for HIPAA-compliant data handling."
                results={[
                  "Achieved 99.99% system uptime",
                  "Reduced infrastructure costs by 40%",
                  "Improved data access speed by 60%",
                  "Zero security incidents during transition"
                ]}
                technologies={[
                  "AWS", "Terraform",
                  "Docker", "Kubernetes",
                  "AWS CloudWatch", "AWS GuardDuty"
                ]}
                testimonial={{
                  quote: "The migration was seamless, and the new infrastructure has exceeded our expectations in terms of performance and security.",
                  author: "Dr. Michael Roberts",
                  title: "Director of IT Operations"
                }}
              />

              <CaseStudy
                industry="Government"
                challenge="A state government agency struggled with outdated infrastructure, leading to performance issues and high maintenance costs."
                solution="Designed and implemented a secure cloud migration strategy, including automated deployment pipelines and comprehensive monitoring systems."
                results={[
                  "Reduced system deployment time by 75%",
                  "Achieved 45% cost savings",
                  "Improved system performance by 200%",
                  "Enhanced security posture"
                ]}
                technologies={[
                  "Azure Government", "Azure DevOps",
                  "PowerShell", "Azure Monitor"
                ]}
                testimonial={{
                  quote: "Robavo's expertise in government cloud solutions helped us modernize while maintaining strict security requirements.",
                  author: "James Wilson",
                  title: "Government IT Director"
                }}
              />
            </div>
            
            <div className="mt-12 text-center">
              <Link to="/case-studies" className="btn-secondary group">
                View All Case Studies
                <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}