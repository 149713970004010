import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../lib/auth';
import { Loader2 } from 'lucide-react';

export function ProtectedAdminRoute({ children }: { children: React.ReactNode }) {
  const { user, loading, isAdmin } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white pt-28 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-purple-500" />
      </div>
    );
  }

  // Special case for engineering@ovabor.com
  if (user?.email === 'engineering@ovabor.com') {
    return <>{children}</>;
  }

  // If no user, redirect to login
  if (!user) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  // If user is not admin, redirect to client dashboard
  if (!isAdmin) {
    return <Navigate to="/client/dashboard" state={{ from: location }} replace />;
  }

  // User is authenticated and is an admin
  return <>{children}</>;
}