import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronRight, ArrowRight, Monitor, Code, Laptop, GitBranch } from 'lucide-react';
import { CaseStudy } from '../CaseStudy';

export function DigitalWorkplace() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <section className="pt-32 pb-16 sm:pt-40 sm:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="mb-8 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <Link to="/services" className="inline-flex items-center text-purple-500 hover:text-purple-400 transition-colors group">
              <ArrowLeft className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:-translate-x-1" />
              Back to Services
            </Link>
          </div>

          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">Digital Workplace & Software Engineering</h1>
            <p className="text-xl text-gray-400 mb-12 max-w-3xl">
              Transform your workplace with modern digital solutions and custom software engineering services.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Software Engineering</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Custom software development and engineering solutions:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Full-stack web application development",
                      "Mobile app development (iOS & Android)",
                      "Enterprise software solutions",
                      "API development and integration",
                      "DevOps and CI/CD implementation",
                      "Legacy system modernization",
                      "Microservices architecture",
                      "Cloud-native development"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Code className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Development Practices</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Industry-leading software development practices:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Agile development methodology",
                      "Test-driven development (TDD)",
                      "Continuous integration/deployment",
                      "Code quality and review processes",
                      "Security-first development",
                      "Performance optimization",
                      "Documentation and knowledge transfer"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <GitBranch className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-12">
              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <h2 className="text-2xl font-bold mb-6">Digital Workplace</h2>
                <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <p className="text-gray-400 mb-6">
                    Modern workplace solutions for your team:
                  </p>
                  <ul className="space-y-4">
                    {[
                      "Remote work infrastructure",
                      "Collaboration tools setup",
                      "Cloud workspace solutions",
                      "Digital transformation strategy",
                      "Employee experience platforms",
                      "Productivity tool integration",
                      "Secure access management"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <Monitor className="h-5 w-5 text-purple-500 mr-3 mt-1" />
                        <span className="text-gray-400">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
                <div className="bg-purple-600/10 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20">
                  <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Digital Workplace?</h2>
                  <p className="text-gray-400 mb-6">
                    Book a free consultation with our experts and discover how we can help you build better software and create a more efficient workplace.
                  </p>
                  <Link to="/contact" className="btn-primary group">
                    Book Your Free Consultation
                    <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Case Studies Section */}
          <div className="mt-24">
            <h2 className="text-2xl font-bold mb-12">Client Success Stories</h2>
            <div className="grid lg:grid-cols-2 gap-8">
              <CaseStudy
                industry="Technology"
                challenge="A large enterprise needed to modernize their legacy applications while maintaining business continuity and improving developer productivity."
                solution="Implemented a modern microservices architecture with CI/CD pipelines, containerization, and automated testing. Established DevSecOps practices and cloud-native development workflows."
                results={[
                  "Reduced deployment time from days to minutes",
                  "Improved code quality by 60%",
                  "Increased developer productivity by 40%",
                  "Achieved 99.9% test automation coverage"
                ]}
                technologies={[
                  "Kubernetes", "Docker", "Jenkins",
                  "GitLab", "Terraform", "Prometheus"
                ]}
                testimonial={{
                  quote: "The modernization of our development workflow has transformed how we deliver software.",
                  author: "Alex Kumar",
                  title: "VP of Engineering"
                }}
              />

              <CaseStudy
                industry="Government"
                challenge="A government agency with 10,000+ employees needed to transform their workplace for hybrid work while ensuring security and compliance."
                solution="Deployed a comprehensive digital workplace solution including identity management, collaboration tools, and secure remote access infrastructure."
                results={[
                  "Enabled secure remote work for 10,000+ employees",
                  "Reduced IT support tickets by 65%",
                  "Improved employee satisfaction by 45%",
                  "Achieved FedRAMP compliance"
                ]}
                technologies={[
                  "Microsoft 365", "Azure AD",
                  "VMware Workspace ONE", "Okta"
                ]}
                testimonial={{
                  quote: "Robavo's digital workplace solution has revolutionized how our agency works, while maintaining the highest security standards.",
                  author: "Patricia Moore",
                  title: "Digital Transformation Director"
                }}
              />
            </div>
            
            <div className="mt-12 text-center">
              <Link to="/case-studies" className="btn-secondary group">
                View All Case Studies
                <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}