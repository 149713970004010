import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, ChevronDown, UserCircle, HelpCircle, LogOut } from 'lucide-react';
import { useAuth } from '../../lib/auth';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const { user, signOut } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
    setIsServicesOpen(false);
    setIsUserMenuOpen(false);
  }, [location]);

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled ? 'bg-black/95 backdrop-blur-sm shadow-lg' : 'bg-black'
    }`}>
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex items-center group">
              <img 
                src="https://www.robavo.xyz/logo.fc5e6c6a.svg" 
                alt="Robavo" 
                className="h-8 transition-transform duration-300 group-hover:scale-105" 
              />
              <span className="ml-2 text-white font-medium border-l border-gray-700 pl-2 hidden sm:inline">
                Consulting
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex sm:items-center sm:space-x-8">
            <div className="relative">
              <button
                onClick={() => setIsServicesOpen(!isServicesOpen)}
                className="text-gray-300 hover:text-white inline-flex items-center transition-colors duration-200"
              >
                Services
                <ChevronDown className={`ml-1 h-4 w-4 transition-transform duration-200 ${
                  isServicesOpen ? 'rotate-180' : ''
                }`} />
              </button>
              
              {isServicesOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-lg shadow-lg bg-black/95 backdrop-blur-sm ring-1 ring-black ring-opacity-5 transform transition-all duration-200">
                  <div className="py-1" role="menu">
                    <Link
                      to="/services/cloud"
                      className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                    >
                      Cloud Services
                    </Link>
                    <Link
                      to="/services/security"
                      className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                    >
                      Security Services
                    </Link>
                    <Link
                      to="/services/digital-workplace"
                      className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                    >
                      Digital Workplace
                    </Link>
                    <Link
                      to="/services/ai-automation"
                      className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                    >
                      AI & Automation
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <Link 
              to="/case-studies" 
              className="text-gray-300 hover:text-white transition-colors duration-200"
            >
              Case Studies
            </Link>
            <Link 
              to="/blog" 
              className="text-gray-300 hover:text-white transition-colors duration-200"
            >
              Blog
            </Link>
            <Link 
              to="/about" 
              className="text-gray-300 hover:text-white transition-colors duration-200"
            >
              About
            </Link>
            {user ? (
              <div className="relative">
                <button
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                  className="text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Account
                  <ChevronDown className={`ml-1 h-4 w-4 inline-block transition-transform duration-200 ${
                    isUserMenuOpen ? 'rotate-180' : ''
                  }`} />
                </button>
                {isUserMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-black/95 backdrop-blur-sm rounded-lg shadow-lg py-2 z-50">
                    <Link
                      to="/client/dashboard"
                      className="block px-4 py-2 text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors"
                    >
                      Dashboard
                    </Link>
                    <Link
                      to="/client/profile"
                      className="block px-4 py-2 text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors"
                    >
                      Profile
                    </Link>
                    <Link
                      to="/support"
                      className="block px-4 py-2 text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors"
                    >
                      Support
                    </Link>
                    <div className="border-t border-gray-800 my-2" />
                    <button
                      onClick={() => signOut()}
                      className="block w-full text-left px-4 py-2 text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors"
                    >
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/support"
                className="btn-primary"
              >
                Support
              </Link>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="sm:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-white transition-colors duration-200"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="sm:hidden bg-black/95 backdrop-blur-sm mobile-menu">
            <div className="pt-2 pb-3 space-y-1">
              <div className="space-y-1">
                <Link
                  to="/services/cloud"
                  className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                >
                  Cloud Services
                </Link>
                <Link
                  to="/services/security"
                  className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                >
                  Security Services
                </Link>
                <Link
                  to="/services/digital-workplace"
                  className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                >
                  Digital Workplace
                </Link>
                <Link
                  to="/services/ai-automation"
                  className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                >
                  AI & Automation
                </Link>
              </div>
              <Link
                to="/case-studies"
                className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
              >
                Case Studies
              </Link>
              <Link
                to="/blog"
                className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
              >
                Blog
              </Link>
              <Link
                to="/about"
                className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
              >
                About
              </Link>
              {user ? (
                <>
                  <Link
                    to="/client/dashboard"
                    className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/client/profile"
                    className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                  >
                    Profile
                  </Link>
                  <Link
                    to="/support"
                    className="block px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                  >
                    Support
                  </Link>
                  <button
                    onClick={() => signOut()}
                    className="block w-full text-left px-3 py-2 text-base text-gray-300 hover:text-white hover:bg-purple-600/10 transition-colors duration-200"
                  >
                    Sign Out
                  </button>
                </>
              ) : (
                <Link
                  to="/support"
                  className="block px-3 py-2 text-base bg-purple-600 text-white hover:bg-purple-700 transition-colors duration-200"
                >
                  Support
                </Link>
              )}
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}