import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Cloud, Monitor, Brain, ChevronRight, CheckCircle, ArrowRight } from 'lucide-react';

export function Home() {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observerRef.current?.observe(el);
    });

    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="bg-black text-white">
      {/* Hero Section */}
      <section className="min-h-screen flex items-center justify-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32 relative z-10">
          <div className="text-center animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h1 className="text-5xl md:text-7xl font-bold mb-8 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Cut IT Costs by 40% While Improving Performance
            </h1>
            <p className="text-xl text-gray-400 mb-12 max-w-3xl mx-auto">
              We help government agencies and businesses modernize their IT infrastructure, reduce costs, and enhance security without disrupting operations.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link to="/contact" className="btn-primary group">
                Get Your Free Cost Analysis
                <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link to="/services" className="btn-secondary">
                Explore Our Services
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Services Overview */}
      <section className="section-spacing bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-3xl font-bold mb-16 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            Our Core Services
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Cloud,
                title: "Cloud Services",
                description: "Expert cloud migration and management for AWS, Azure, and Google Cloud.",
                link: "/services/cloud"
              },
              {
                icon: Shield,
                title: "Security Services",
                description: "Comprehensive cybersecurity solutions to protect your organization.",
                link: "/services/security"
              },
              {
                icon: Monitor,
                title: "Digital Workplace",
                description: "Modern solutions for remote and hybrid work environments.",
                link: "/services/digital-workplace"
              },
              {
                icon: Brain,
                title: "AI & Automation",
                description: "Cutting-edge AI solutions to streamline your operations.",
                link: "/services/ai-automation"
              }
            ].map((service, index) => (
              <Link 
                key={service.title}
                to={service.link} 
                className="service-card group bg-black/50 backdrop-blur-sm p-8 rounded-lg animate-on-scroll opacity-0 translate-y-4 transition-all duration-700 flex flex-col"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <service.icon className="h-12 w-12 text-purple-500 mb-6 transition-transform duration-300 group-hover:scale-110" />
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-400 mb-6 flex-grow">{service.description}</p>
                <span className="text-purple-500 group-hover:text-purple-400 inline-flex items-center">
                  Learn more 
                  <ChevronRight className="h-4 w-4 ml-2 transition-transform duration-300 group-hover:translate-x-1" />
                </span>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section className="section-spacing relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-black pointer-events-none" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <h2 className="text-center text-3xl font-bold mb-16 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            How We Work
          </h2>
          <div className="grid md:grid-cols-3 gap-12">
            {[
              {
                number: "1",
                title: "Strategic Assessment",
                description: "We analyze your infrastructure, security posture, and business objectives to create a tailored transformation roadmap."
              },
              {
                number: "2",
                title: "Custom Solution Design",
                description: "Our experts architect a solution using best-in-class technology, built for your specific requirements and compliance needs."
              },
              {
                number: "3",
                title: "Seamless Implementation",
                description: "We handle the entire implementation with zero downtime, comprehensive testing, and 24/7 support to ensure your success."
              }
            ].map((step, index) => (
              <div 
                key={step.number}
                className="text-center animate-on-scroll opacity-0 translate-y-4 transition-all duration-700"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <div className="bg-purple-600 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-6 transform transition-transform duration-300 hover:scale-110 hover:rotate-12">
                  <span className="text-xl font-bold">{step.number}</span>
                </div>
                <h3 className="text-2xl font-bold mb-4">{step.title}</h3>
                <p className="text-gray-400">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="section-spacing bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-3xl font-bold mb-16 animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            Why Choose Us
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Expert Team",
                description: "15+ years combined experience in enterprise IT solutions and government systems."
              },
              {
                title: "Proven Track Record",
                description: "40% average cost reduction and 300% performance improvement for our clients."
              },
              {
                title: "24/7 Support",
                description: "Round-the-clock monitoring and support from our US-based team of certified experts."
              },
              {
                title: "Cost-Effective",
                description: "Competitive pricing with clear ROI for your investment."
              },
              {
                title: "Security First",
                description: "Enterprise-grade security in everything we do."
              },
              {
                title: "Tailored Solutions",
                description: "Custom solutions designed for your specific needs and goals."
              }
            ].map((feature, index) => (
              <div 
                key={feature.title}
                className="flex items-start animate-on-scroll opacity-0 translate-y-4 transition-all duration-700"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <CheckCircle className="h-6 w-6 text-purple-500 mt-1 flex-shrink-0" />
                <div className="ml-4">
                  <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="section-spacing relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-black pointer-events-none" />
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <div className="animate-on-scroll opacity-0 translate-y-4 transition-all duration-700">
            <h2 className="text-4xl font-bold mb-8">Ready to Transform Your IT Infrastructure?</h2>
            <p className="text-xl text-gray-400 mb-12">
              Book a 30-minute call to get:
              <br />
              • Custom cost analysis • Security assessment • Implementation timeline • Clear pricing
              <br />
              No commitment required. Just practical insights for your IT decisions.
            </p>
            <Link to="/contact" className="btn-primary group">
              Book Your Free Consultation
              <ArrowRight className="inline-block ml-2 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}