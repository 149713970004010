import React from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';

export function VerifyEmailPage() {
  return (
    <div className="min-h-screen bg-black text-white flex items-center justify-center px-4">
      <div className="max-w-md w-full text-center">
        <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-8">
          <Mail className="h-16 w-16 text-purple-500 mx-auto mb-6" />
          <h1 className="text-3xl font-bold mb-4">Check Your Email</h1>
          <p className="text-gray-400 mb-6">
            We've sent you an email with a link to verify your account. Please check your inbox and follow the instructions.
          </p>
          <Link to="/auth/login" className="btn-primary">
            Return to Login
          </Link>
        </div>
      </div>
    </div>
  );
}