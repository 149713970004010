import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, 
  Filter, 
  ArrowRight,
  Building2,
  Mail,
  Phone,
  Globe,
  MapPin
} from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface Client {
  id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  company_name: string;
  industry: string | null;
  website: string | null;
  billing_address: string | null;
  created_at: string;
}

export function AdminClientList() {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [industryFilter, setIndustryFilter] = useState('all');

  useEffect(() => {
    fetchClients();
  }, []);

  async function fetchClients() {
    try {
      const { data, error } = await supabase
        .from('client_users')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setClients(data || []);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setLoading(false);
    }
  }

  const industries = ['all', ...new Set(clients.map(client => client.industry || 'Other'))];

  const filteredClients = clients.filter(client => {
    const matchesSearch = 
      client.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.company_name.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesIndustry = industryFilter === 'all' || client.industry === industryFilter;

    return matchesSearch && matchesIndustry;
  });

  return (
    <div className="min-h-screen bg-black text-white pt-28">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Clients</h1>
        </div>

        {/* Search and Filters */}
        <div className="mb-8 space-y-4">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search clients..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg pl-12 pr-4 py-3 text-white placeholder-gray-400 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          <div className="flex items-center space-x-4">
            <Filter className="h-5 w-5 text-gray-400" />
            <div className="space-x-2">
              {industries.map((industry) => (
                <button
                  key={industry}
                  onClick={() => setIndustryFilter(industry)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    industryFilter === industry
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-900 text-gray-400 hover:bg-gray-800'
                  }`}
                >
                  {industry === 'all' ? 'All Industries' : industry}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Clients Grid */}
        <div className="grid gap-6">
          {loading ? (
            [...Array(3)].map((_, index) => (
              <div key={index} className="animate-pulse bg-gray-900/50 rounded-lg p-6">
                <div className="h-6 bg-gray-800 rounded w-1/4 mb-4" />
                <div className="h-4 bg-gray-800 rounded w-3/4" />
              </div>
            ))
          ) : filteredClients.length > 0 ? (
            filteredClients.map(client => (
              <Link
                key={client.id}
                to={`/admin/clients/${client.id}`}
                className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6 hover:bg-gray-800/50 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <div className="flex items-center space-x-3 mb-2">
                      <Building2 className="h-5 w-5 text-purple-500" />
                      <h3 className="text-xl font-semibold">{client.company_name}</h3>
                      {client.industry && (
                        <span className="text-sm text-gray-400">({client.industry})</span>
                      )}
                    </div>

                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div className="flex items-center text-gray-400">
                        <Mail className="h-4 w-4 mr-2" />
                        {client.email}
                      </div>
                      {client.phone && (
                        <div className="flex items-center text-gray-400">
                          <Phone className="h-4 w-4 mr-2" />
                          {client.phone}
                        </div>
                      )}
                      {client.website && (
                        <div className="flex items-center text-gray-400">
                          <Globe className="h-4 w-4 mr-2" />
                          {client.website}
                        </div>
                      )}
                      {client.billing_address && (
                        <div className="flex items-center text-gray-400">
                          <MapPin className="h-4 w-4 mr-2" />
                          {client.billing_address}
                        </div>
                      )}
                    </div>
                  </div>

                  <ArrowRight className="h-5 w-5 text-gray-400" />
                </div>
              </Link>
            ))
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-400">No clients found matching your criteria.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}