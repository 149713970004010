import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseServiceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im55ZGxhZ2preXVwZXJpZ3dlYXByIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczNzA5MjU4MCwiZXhwIjoyMDUyNjY4NTgwfQ.jOpTp0_cyh9D8irLpAqJIBNaxD8XRxDXX3qoNNHzTiQ';

if (!supabaseUrl) {
  throw new Error('Missing Supabase URL environment variable');
}

// Create a singleton instance with custom fetch settings
export const supabase = createClient(supabaseUrl, supabaseServiceKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  },
  global: {
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    }
  },
  db: {
    schema: 'public'
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  }
});

// Cache for query results
const queryCache = new Map();

// Wrapper for select queries with caching
export async function cachedQuery(
  table: string,
  query: any,
  options: { ttl?: number; force?: boolean } = {}
) {
  const { ttl = 60000, force = false } = options; // Default 1 minute TTL
  const cacheKey = `${table}:${JSON.stringify(query)}`;

  // Check cache unless force refresh is requested
  if (!force) {
    const cached = queryCache.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < ttl) {
      return cached.data;
    }
  }

  // Perform query
  const { data, error } = await query;
  
  if (error) throw error;

  // Update cache
  queryCache.set(cacheKey, {
    data,
    timestamp: Date.now()
  });

  return data;
}

// Clear cache for a specific table
export function clearTableCache(table: string) {
  for (const key of queryCache.keys()) {
    if (key.startsWith(`${table}:`)) {
      queryCache.delete(key);
    }
  }
}

// Clear entire cache
export function clearCache() {
  queryCache.clear();
}