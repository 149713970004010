import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  PlusCircle, Edit, Trash2, Eye, Clock, Filter,
  CheckCircle, XCircle, AlertCircle, Search 
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type { BlogPostWithCategories } from '../../lib/types';

export function BlogAdmin() {
  const [posts, setPosts] = useState<BlogPostWithCategories[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<'all' | 'published' | 'draft'>('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchPosts();
  }, []);

  async function fetchPosts() {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select(`
          *,
          categories:blog_post_categories(
            category:blog_categories(*)
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }

  async function deletePost(id: string) {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    try {
      const { error } = await supabase
        .from('blog_posts')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setPosts(posts.filter(post => post.id !== id));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  }

  async function togglePublished(id: string, currentStatus: boolean) {
    try {
      const { error } = await supabase
        .from('blog_posts')
        .update({ 
          published: !currentStatus,
          published_at: !currentStatus ? new Date().toISOString() : null
        })
        .eq('id', id);

      if (error) throw error;
      
      setPosts(posts.map(post => 
        post.id === id 
          ? { ...post, published: !currentStatus }
          : post
      ));
    } catch (error) {
      console.error('Error updating post:', error);
    }
  }

  const filteredPosts = posts.filter(post => {
    const matchesFilter = 
      filter === 'all' || 
      (filter === 'published' && post.published) ||
      (filter === 'draft' && !post.published);

    const matchesSearch = 
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.excerpt?.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesFilter && matchesSearch;
  });

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Blog Posts</h1>
          <Link 
            to="/admin/blog/new" 
            className="btn-primary"
          >
            <PlusCircle className="h-5 w-5 mr-2" />
            New Post
          </Link>
        </div>

        <div className="mb-8 space-y-4">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search posts..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg pl-12 pr-4 py-3 text-white placeholder-gray-400 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          <div className="flex items-center space-x-4">
            <Filter className="h-5 w-5 text-gray-400" />
            <div className="space-x-2">
              {(['all', 'published', 'draft'] as const).map((status) => (
                <button
                  key={status}
                  onClick={() => setFilter(status)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    filter === status
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-900 text-gray-400 hover:bg-gray-800'
                  }`}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </div>

        {loading ? (
          <div className="space-y-4">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="animate-pulse bg-gray-900 p-6 rounded-lg">
                <div className="h-6 bg-gray-800 rounded w-1/4 mb-4" />
                <div className="h-4 bg-gray-800 rounded w-3/4" />
              </div>
            ))}
          </div>
        ) : (
          <div className="space-y-4">
            {filteredPosts.map((post) => (
              <div 
                key={post.id}
                className="bg-gray-900 rounded-lg p-6 flex items-center justify-between"
              >
                <div>
                  <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
                  <div className="flex items-center space-x-4 text-sm text-gray-400">
                    <span className="flex items-center">
                      <Clock className="h-4 w-4 mr-1" />
                      {new Date(post.created_at).toLocaleDateString()}
                    </span>
                    <span className={`flex items-center ${
                      post.published ? 'text-green-400' : 'text-yellow-400'
                    }`}>
                      {post.published ? (
                        <CheckCircle className="h-4 w-4 mr-1" />
                      ) : (
                        <AlertCircle className="h-4 w-4 mr-1" />
                      )}
                      {post.published ? 'Published' : 'Draft'}
                    </span>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => togglePublished(post.id, post.published)}
                    className={`p-2 rounded-lg transition-colors ${
                      post.published
                        ? 'bg-yellow-600/10 text-yellow-400 hover:bg-yellow-600/20'
                        : 'bg-green-600/10 text-green-400 hover:bg-green-600/20'
                    }`}
                  >
                    {post.published ? <XCircle /> : <CheckCircle />}
                  </button>
                  <Link
                    to={`/blog/${post.slug}`}
                    target="_blank"
                    className="p-2 rounded-lg bg-blue-600/10 text-blue-400 hover:bg-blue-600/20 transition-colors"
                  >
                    <Eye />
                  </Link>
                  <Link
                    to={`/admin/blog/edit/${post.id}`}
                    className="p-2 rounded-lg bg-purple-600/10 text-purple-400 hover:bg-purple-600/20 transition-colors"
                  >
                    <Edit />
                  </Link>
                  <button
                    onClick={() => deletePost(post.id)}
                    className="p-2 rounded-lg bg-red-600/10 text-red-400 hover:bg-red-600/20 transition-colors"
                  >
                    <Trash2 />
                  </button>
                </div>
              </div>
            ))}

            {filteredPosts.length === 0 && (
              <div className="text-center py-12 text-gray-400">
                No posts found matching your criteria.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}