import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { 
  Loader2, 
  Save, 
  Settings as SettingsIcon,
  Bell,
  Lock,
  AlertTriangle,
  Trash2
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useAuth } from '../../lib/auth';

const settingsSchema = z.object({
  current_password: z.string().min(1, 'Current password is required'),
  new_password: z.string().min(6, 'Password must be at least 6 characters'),
  confirm_password: z.string()
}).refine(data => data.new_password === data.confirm_password, {
  message: "Passwords don't match",
  path: ['confirm_password']
});

type SettingsFormData = z.infer<typeof settingsSchema>;

export function Settings() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<SettingsFormData>({
    resolver: zodResolver(settingsSchema)
  });

  const onSubmit = async (data: SettingsFormData) => {
    setSaving(true);
    setError(null);
    setSuccess(false);

    try {
      const { error } = await supabase.auth.updateUser({
        password: data.new_password
      });

      if (error) throw error;

      setSuccess(true);
      reset();
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to update password. Please ensure your current password is correct.');
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      // Delete user data first
      const { error: dataError } = await supabase
        .from('client_users')
        .delete()
        .eq('user_id', user?.id);

      if (dataError) throw dataError;

      // Delete auth user
      const { error: authError } = await supabase.auth.admin.deleteUser(
        user?.id as string
      );

      if (authError) throw authError;

      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to delete account. Please contact support.');
    }
  };

  return (
    <div className="min-h-screen bg-black text-white pt-28">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex items-center mb-8">
          <SettingsIcon className="h-8 w-8 text-purple-500 mr-3" />
          <h1 className="text-3xl font-bold">Account Settings</h1>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-900/50 border border-red-500/20 rounded-lg text-red-400">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-6 p-4 bg-green-900/50 border border-green-500/20 rounded-lg text-green-400">
            Password updated successfully
          </div>
        )}

        {/* Security Settings */}
        <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6 mb-8">
          <div className="flex items-center mb-6">
            <Lock className="h-5 w-5 text-purple-500 mr-2" />
            <h2 className="text-xl font-bold">Security Settings</h2>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-white mb-2">
                Current Password
              </label>
              <input
                type="password"
                {...register('current_password')}
                className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
              {errors.current_password && (
                <p className="mt-1 text-sm text-red-500">{errors.current_password.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white mb-2">
                New Password
              </label>
              <input
                type="password"
                {...register('new_password')}
                className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
              {errors.new_password && (
                <p className="mt-1 text-sm text-red-500">{errors.new_password.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white mb-2">
                Confirm New Password
              </label>
              <input
                type="password"
                {...register('confirm_password')}
                className="w-full px-4 py-2 rounded-lg bg-gray-900 border border-gray-700 text-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
              {errors.confirm_password && (
                <p className="mt-1 text-sm text-red-500">{errors.confirm_password.message}</p>
              )}
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={saving}
                className="btn-primary"
              >
                {saving ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5 mr-2" />
                    Updating...
                  </>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    Update Password
                  </>
                )}
              </button>
            </div>
          </form>
        </div>

        {/* Delete Account */}
        <div className="bg-red-900/10 backdrop-blur-sm rounded-lg p-6">
          <div className="flex items-center mb-6">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <h2 className="text-xl font-bold">Delete Account</h2>
          </div>

          <p className="text-gray-400 mb-6">
            Permanently delete your account and all associated data. This action cannot be undone.
          </p>

          {!showDeleteConfirm ? (
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="flex items-center px-4 py-2 rounded-lg bg-red-600/10 text-red-500 hover:bg-red-600/20 transition-colors"
            >
              <Trash2 className="h-5 w-5 mr-2" />
              Delete Account
            </button>
          ) : (
            <div className="bg-red-900/20 rounded-lg p-4">
              <p className="text-red-400 mb-4">
                Are you sure you want to delete your account? This will:
              </p>
              <ul className="list-disc list-inside text-red-400 mb-4">
                <li>Delete all your projects and tickets</li>
                <li>Remove all your documents</li>
                <li>Cancel any pending invoices</li>
                <li>Permanently delete your account</li>
              </ul>
              <div className="flex space-x-4">
                <button
                  onClick={handleDeleteAccount}
                  className="flex items-center px-4 py-2 rounded-lg bg-red-600 text-white hover:bg-red-700 transition-colors"
                >
                  <Trash2 className="h-5 w-5 mr-2" />
                  Confirm Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300 hover:bg-gray-700 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}