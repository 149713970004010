import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BarChart3,
  FileText,
  FolderOpen,
  LifeBuoy,
  Clock,
  AlertCircle,
  CheckCircle2,
  ArrowRight,
  MessageSquare
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type {
  ClientProject,
  ClientTicket,
  ClientInvoice
} from '../../lib/types';

// Client navigation items
const clientNav = [
  { name: 'Dashboard', href: '/client/dashboard', icon: BarChart3 },
  { name: 'Projects', href: '/client/projects', icon: FolderOpen },
  { name: 'Tickets', href: '/client/tickets', icon: LifeBuoy },
  { name: 'Documents', href: '/client/documents', icon: FileText }
];

export function ClientDashboard() {
  const [projects, setProjects] = useState<ClientProject[]>([]);
  const [tickets, setTickets] = useState<ClientTicket[]>([]);
  const [invoices, setInvoices] = useState<ClientInvoice[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  async function fetchDashboardData() {
    try {
      const [projectsData, ticketsData, invoicesData] = await Promise.all([
        supabase
          .from('client_projects')
          .select('*')
          .order('updated_at', { ascending: false })
          .limit(5),
        supabase
          .from('client_tickets')
          .select('*')
          .order('updated_at', { ascending: false })
          .limit(5),
        supabase
          .from('client_invoices')
          .select('*')
          .order('due_date', { ascending: true })
          .limit(5)
      ]);

      if (projectsData.error) throw projectsData.error;
      if (ticketsData.error) throw ticketsData.error;
      if (invoicesData.error) throw invoicesData.error;

      setProjects(projectsData.data || []);
      setTickets(ticketsData.data || []);
      setInvoices(invoicesData.data || []);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  }

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Fixed Navigation Header */}
      <div className="fixed top-0 left-0 right-0 z-20 bg-black/95 backdrop-blur-sm border-b border-gray-800">
        <div className="max-w-7xl mx-auto">
          {/* Main Navigation */}
          <div className="h-16 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
            <div className="flex items-center space-x-8">
              {clientNav.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="flex items-center text-gray-400 hover:text-white transition-colors"
                >
                  <item.icon className="h-5 w-5 mr-2" />
                  {item.name}
                </Link>
              ))}
            </div>

            <div className="flex items-center space-x-4">
              <Link to="/client/profile" className="text-gray-400 hover:text-white transition-colors">
                Profile
              </Link>
              <Link to="/client/support" className="text-gray-400 hover:text-white transition-colors">
                Support
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-24 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Client Dashboard</h1>
          <div className="space-x-4">
            <Link to="/client/projects/new" className="btn-primary">
              New Project
            </Link>
            <Link to="/client/tickets/new" className="btn-secondary">
              Create Ticket
            </Link>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <div className="flex items-center mb-4">
              <FolderOpen className="h-6 w-6 text-purple-500 mr-3" />
              <h3 className="text-lg font-semibold">Active Projects</h3>
            </div>
            <p className="text-3xl font-bold">
              {projects.filter(p => p.status === 'in_progress').length}
            </p>
          </div>

          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <div className="flex items-center mb-4">
              <LifeBuoy className="h-6 w-6 text-purple-500 mr-3" />
              <h3 className="text-lg font-semibold">Open Tickets</h3>
            </div>
            <p className="text-3xl font-bold">
              {tickets.filter(t => t.status === 'open').length}
            </p>
          </div>

          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <div className="flex items-center mb-4">
              <FileText className="h-6 w-6 text-purple-500 mr-3" />
              <h3 className="text-lg font-semibold">Pending Invoices</h3>
            </div>
            <p className="text-3xl font-bold">
              {invoices.filter(i => i.status === 'sent').length}
            </p>
          </div>

          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
            <div className="flex items-center mb-4">
              <BarChart3 className="h-6 w-6 text-purple-500 mr-3" />
              <h3 className="text-lg font-semibold">Project Progress</h3>
            </div>
            <p className="text-3xl font-bold">
              {Math.round((projects.filter(p => p.status === 'completed').length / (projects.length || 1)) * 100)}%
            </p>
          </div>
        </div>

        {/* Recent Projects */}
        <div className="mb-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Recent Projects</h2>
            <Link to="/client/projects" className="text-purple-500 hover:text-purple-400 flex items-center">
              View All
              <ArrowRight className="h-4 w-4 ml-2" />
            </Link>
          </div>

          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-800">
                    <th className="text-left p-4">Project Name</th>
                    <th className="text-left p-4">Status</th>
                    <th className="text-left p-4">Start Date</th>
                    <th className="text-left p-4">End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index} className="animate-pulse">
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-48" /></td>
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-24" /></td>
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-32" /></td>
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-32" /></td>
                      </tr>
                    ))
                  ) : projects.length > 0 ? (
                    projects.map(project => (
                      <tr key={project.id} className="border-b border-gray-800 hover:bg-gray-800/50">
                        <td className="p-4">
                          <Link to={`/client/projects/${project.id}`} className="text-purple-500 hover:text-purple-400">
                            {project.name}
                          </Link>
                        </td>
                        <td className="p-4">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                            ${project.status === 'completed' ? 'bg-green-100 text-green-800' :
                              project.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                              project.status === 'planning' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-gray-100 text-gray-800'}`}>
                            {project.status.replace('_', ' ')}
                          </span>
                        </td>
                        <td className="p-4">{project.start_date || '-'}</td>
                        <td className="p-4">{project.end_date || '-'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="p-4 text-center text-gray-400">
                        No projects found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Recent Tickets */}
        <div className="mb-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Recent Tickets</h2>
            <Link to="/client/tickets" className="text-purple-500 hover:text-purple-400 flex items-center">
              View All
              <ArrowRight className="h-4 w-4 ml-2" />
            </Link>
          </div>

          <div className="grid gap-6">
            {loading ? (
              [...Array(3)].map((_, index) => (
                <div key={index} className="animate-pulse bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
                  <div className="h-6 bg-gray-800 rounded w-1/3 mb-4" />
                  <div className="h-4 bg-gray-800 rounded w-2/3" />
                </div>
              ))
            ) : tickets.length > 0 ? (
              tickets.map(ticket => (
                <Link
                  key={ticket.id}
                  to={`/client/tickets/${ticket.id}`}
                  className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6 hover:bg-gray-800/50 transition-colors"
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <div className="flex items-center space-x-3 mb-2">
                        {ticket.priority === 'urgent' ? (
                          <AlertCircle className="h-6 w-6 text-red-500" />
                        ) : ticket.status === 'resolved' ? (
                          <CheckCircle2 className="h-6 w-6 text-green-500" />
                        ) : (
                          <Clock className="h-6 w-6 text-yellow-500" />
                        )}
                        <h3 className="font-semibold">{ticket.title}</h3>
                      </div>
                      <p className="text-sm text-gray-400">{ticket.description}</p>
                      <div className="flex items-center space-x-4 text-sm text-gray-400 mt-4">
                        <span className="flex items-center">
                          <Clock className="h-4 w-4 mr-1" />
                          {new Date(ticket.created_at).toLocaleDateString()}
                        </span>
                        <span className="flex items-center">
                          <MessageSquare className="h-4 w-4 mr-1" />
                          3 Comments
                        </span>
                      </div>
                    </div>
                    <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${ticket.status === 'resolved' ? 'bg-green-100 text-green-800' :
                        ticket.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                        ticket.status === 'open' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-gray-100 text-gray-800'}`}>
                      {ticket.status.replace('_', ' ')}
                    </span>
                  </div>
                </Link>
              ))
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-400">No tickets found</p>
              </div>
            )}
          </div>
        </div>

        {/* Recent Invoices */}
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Recent Invoices</h2>
            <Link to="/client/invoices" className="text-purple-500 hover:text-purple-400 flex items-center">
              View All
              <ArrowRight className="h-4 w-4 ml-2" />
            </Link>
          </div>

          <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-800">
                    <th className="text-left p-4">Invoice Number</th>
                    <th className="text-left p-4">Amount</th>
                    <th className="text-left p-4">Status</th>
                    <th className="text-left p-4">Due Date</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index} className="animate-pulse">
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-32" /></td>
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-24" /></td>
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-20" /></td>
                        <td className="p-4"><div className="h-4 bg-gray-800 rounded w-32" /></td>
                      </tr>
                    ))
                  ) : invoices.length > 0 ? (
                    invoices.map(invoice => (
                      <tr key={invoice.id} className="border-b border-gray-800 hover:bg-gray-800/50">
                        <td className="p-4">
                          <Link to={`/client/invoices/${invoice.id}`} className="text-purple-500 hover:text-purple-400">
                            {invoice.invoice_number}
                          </Link>
                        </td>
                        <td className="p-4">{formatCurrency(invoice.amount)}</td>
                        <td className="p-4">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                            ${invoice.status === 'paid' ? 'bg-green-100 text-green-800' :
                              invoice.status === 'overdue' ? 'bg-red-100 text-red-800' :
                              invoice.status === 'sent' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-gray-100 text-gray-800'}`}>
                            {invoice.status}
                          </span>
                        </td>
                        <td className="p-4">{new Date(invoice.due_date).toLocaleDateString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="p-4 text-center text-gray-400">
                        No invoices found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}