import React from 'react';
import { Mail, Phone, MapPin, Clock, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Support() {
  const contactMethods = [
    {
      icon: Phone,
      title: "24/7 Emergency Support",
      description: "+1 (555) 123-4567",
      subtext: "Available 24/7 for critical issues"
    },
    {
      icon: Mail,
      title: "Email Support",
      description: "support@robavo.xyz",
      subtext: "Response within 2 business hours"
    },
    {
      icon: MapPin,
      title: "Office Location",
      description: "100 West Road, Suite 300, Towson, MD 21204",
      subtext: "Monday-Friday, 9:00 AM - 5:00 PM EST"
    }
  ];

  const supportCategories = [
    {
      title: "Project Support",
      items: [
        "Project status inquiries",
        "Change requests",
        "Resource allocation",
        "Timeline adjustments",
        "Budget discussions"
      ]
    },
    {
      title: "Technical Support",
      items: [
        "System access issues",
        "Integration support",
        "Performance concerns",
        "Security incidents",
        "Infrastructure problems"
      ]
    },
    {
      title: "Administrative Support",
      items: [
        "Billing inquiries",
        "Contract questions",
        "Documentation requests",
        "Account management",
        "Compliance matters"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white pt-28">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold mb-8">Support Center</h1>

        {/* Contact Methods */}
        <div className="grid md:grid-cols-3 gap-6 mb-16">
          {contactMethods.map((method, index) => (
            <div key={index} className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
              <div className="flex items-center mb-4">
                <method.icon className="h-6 w-6 text-purple-500 mr-3" />
                <h3 className="font-semibold">{method.title}</h3>
              </div>
              <p className="text-lg mb-2">{method.description}</p>
              <p className="text-sm text-gray-400">{method.subtext}</p>
            </div>
          ))}
        </div>

        {/* Support Categories */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {supportCategories.map((category, index) => (
            <div key={index} className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4">{category.title}</h3>
              <ul className="space-y-3">
                {category.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center text-gray-400">
                    <div className="w-2 h-2 bg-purple-500 rounded-full mr-3" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* SLA Information */}
        <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-8 mb-16">
          <h2 className="text-2xl font-bold mb-6">Response Time Commitments</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="space-y-2">
              <div className="flex items-center text-red-500">
                <Clock className="h-5 w-5 mr-2" />
                <h4 className="font-semibold">Critical Issues</h4>
              </div>
              <p className="text-gray-400">Response within 1 hour</p>
            </div>
            <div className="space-y-2">
              <div className="flex items-center text-yellow-500">
                <Clock className="h-5 w-5 mr-2" />
                <h4 className="font-semibold">High Priority</h4>
              </div>
              <p className="text-gray-400">Response within 4 hours</p>
            </div>
            <div className="space-y-2">
              <div className="flex items-center text-blue-500">
                <Clock className="h-5 w-5 mr-2" />
                <h4 className="font-semibold">Standard Issues</h4>
              </div>
              <p className="text-gray-400">Response within 1 business day</p>
            </div>
          </div>
        </div>

        {/* Client Portal Access */}
        <div className="bg-purple-600/10 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20">
          <h2 className="text-2xl font-bold mb-4">Client Portal</h2>
          <p className="text-gray-400 mb-6">
            Access your project documents, track progress, view invoices, and manage support tickets through our secure client portal.
            Please contact our support team to request portal access credentials.
          </p>
          <div 
            className="inline-flex items-center bg-gray-800 text-gray-400 px-6 py-3 rounded-lg cursor-not-allowed opacity-75"
            onClick={(e) => e.preventDefault()}
          >
            Access Portal
            <ArrowRight className="inline-block ml-2" />
          </div>
        </div>
      </div>
    </div>
  );
}